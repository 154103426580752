<template>
  <div class="text-xs-center" v-if="show_container_can === true">
    <v-row class="ma-1"
      ><v-btn outlined block color="white" @click="dialog_osp = true"
        >OPEN</v-btn
      ></v-row
    >

    <v-row justify="center">
      <v-dialog
        persistent
        no-click-animation
        v-model="dialog_osp"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-snackbar
            v-model="snackbar"
            :multi-line="mode"
            :right="x"
            :top="y"
            :timeout="timeout"
          >
            {{ snackbar_text }}
            <v-btn color="lime" text @click="snackbar = false">Close</v-btn>
          </v-snackbar>
          <v-toolbar dark color="#26c6da">
            <v-toolbar-title>ENUMERATORS LIST</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn dark text @click="fetchData">
                <v-icon>update</v-icon>&nbsp;Refresh
              </v-btn>
              <v-btn dark text @click="dialog_osp = false">Close</v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-card flat>
            <v-card-title>
              <v-btn
                outlined
                color="#26c6da"
                @click="
                  dialog_model = true
                  status = 'create'
                  model = {}
                "
              >
                CREATE
              </v-btn>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="models"
              :search="search"
              :loading="loaded"
            >
              <v-progress-linear
                slot="progress"
                color="#26c6da"
                indeterminate
              ></v-progress-linear>
              <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2" @click="edit_item(item)">
                  mdi-pencil
                </v-icon>
              </template>
            </v-data-table>
          </v-card>
        </v-card>
        <v-dialog v-model="dialog_model" persistent max-width="600px">
          <v-card>
            <v-toolbar color="#26c6da" dark class="elevation-0"
              ><span class="text-h5" v-if="status === 'create'"
                >Enumerator Create
              </span>
              <span class="text-h5" v-if="status === 'edit'"
                >{{ model.name }} Edit</span
              ></v-toolbar
            >
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="Name"
                      v-model="model.name"
                      color="#26c6da"
                      :error-messages="errors.name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="National Id"
                      v-model="model.national_id"
                      color="#26c6da"
                      :error-messages="errors.national_id"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="Phone number"
                      v-model="model.phone_number"
                      color="#26c6da"
                      :error-messages="errors.phone_number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="Email"
                      v-model="model.email"
                      color="#26c6da"
                      :error-messages="errors.email"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-autocomplete
                      item-text="name"
                      item-value="id"
                      :items="statuses"
                      v-model="model.status"
                      label="Status"
                      color="#26c6da"
                      hint="select a status"
                      :menu-props="{ maxHeight: '400' }"
                      persistent-hint
                      :error-messages="errors.status"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="red darken-1"
                text
                @click="
                  dialog_model = false
                  errors = {}
                "
              >
                Close
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="store_submit"
                v-if="status == 'create'"
              >
                Save
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="edit_submit"
                v-if="status == 'edit'"
              >
                Edit
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="downloaded" persistent width="300">
          <v-card color="#26c6da" dark>
            <v-card-text>
              Please stand by
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-dialog>
    </v-row>
  </div>
</template>
<script>
import Vue from "vue"
import axios from "axios"
import Swal from "sweetalert2"
import {
  enumeratorsUrl,
  enumeratorsCreateUrl,
  enumeratorsEditUrl,
  enumeratorsDeleteUrl,
  getHeader
} from "@/config"
export default {
  props: ["studies_id", "studies_name"],
  data() {
    return {
      dialog_osp: false,
      show_container_can: false,
      loaded: false,
      snackbar: false,
      snackbar_text: "",
      y: "",
      x: "",
      mode: "",
      timeout: 6000,
      models: [],
      statuses: [],
      model: {},
      errors: {},
      downloaded: false,
      disabled: false,
      search: "",
      disabled: false,
      dialog_model: false,
      status: "create",
      headers: [
        { text: "National Id", align: "left", value: "national_id" },
        { text: "Name", align: "left", value: "name" },
        {
          text: "Serial",
          align: "left",
          value: "serial"
        },
        { text: "updated", align: "left", value: "updated_at" },
        { text: "Actions", align: "center", value: "actions", sortable: false }
      ]
    }
  },
  beforeMount() {
    var vm = this

    //vm.fetchData()
    vm.show_container_can = true
  },
  watch: {
    dialog_osp: function(newVal, oldVal) {
      // watch it
      var vm = this
      if (newVal == true) {
        vm.fetchData()
      }
    }
  },
  methods: {
    fetchData() {
      var vm = this
      vm.loaded = true
      axios
        .get(enumeratorsUrl + "/" + vm.studies_id, { headers: getHeader() })
        .then(function(response) {
          vm.loaded = false
          Vue.set(vm.$data, "models", response.data.model)
          Vue.set(vm.$data, "statuses", response.data.status)
        })
        .catch(function(error) {
          console.log(error.message)
          if (error.message == "Network Error") {
            vm.snackbar_text =
              "we have detected a slow internet connection! It may interfer with the responsiveness of the BGNIS"
            vm.snackbar = true
            vm.x = "right"
            vm.y = "top"
            vm.mode = "multi-line"
          }
        })
    },
    store_submit() {
      var vm = this
      Swal.fire({
        title: "Submit",
        text: "Do you want to create this Enumerator?",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#26c6da",
        cancelButtonColor: "#F44336",
        confirmButtonText: "Create",
        cancelButtonText: "Cancel"
      }).then(result => {
        if (result.value) {
          vm.downloaded = true
          vm.disabled = true
          var data = {
            name: vm.model.name,
            national_id: vm.model.national_id,
            phone_number: vm.model.phone_number,
            email: vm.model.email,
            status: vm.model.status,
            studies_id: vm.studies_id
          }
          const options = {
            method: "POST",
            headers: getHeader(),
            data: data,
            url: enumeratorsCreateUrl
          }
          axios(options)
            .then(function(response) {
              if (response.data.stored) {
                vm.fetchData()
                vm.dialog_model = false
                vm.errors = {}
                vm.downloaded = false
                vm.disabled = false
                //vm.closeCreate()
                Swal.fire({
                  title: "Created",
                  text: "Created successfully",
                  icon: "success",
                  confirmButtonColor: "#26c6da",
                  confirmButtonText: "OK"
                })
              }
              if (response.data.failed) {
                vm.downloaded = false
                vm.disabled = false
                //vm.closeCreate()
                Swal.fire({
                  title: "Failed",
                  text: "Create failed",
                  icon: "success",
                  confirmButtonColor: "#26c6da",
                  confirmButtonText: "OK"
                })
              }
              if (response.data.unauthorized) {
                Swal.fire({
                  title: "403!",
                  text: "ACCESS DENIED!",
                  icon: "warning",
                  confirmButtonColor: "#26c6da",
                  confirmButtonText: "OK"
                })
                vm.sending = false
                vm.disabled = false
                //vm.closeCreate()
                //vm.$router.go(-1)
              }
            })
            .catch(function(error) {
              console.log(error)
              vm.downloaded = false
              if (error.response.data) {
                Vue.set(vm.$data, "errors", error.response.data.errors)
                vm.disabled = false
              }
              if (error.message == "Network Error") {
                vm.snackbar_text =
                  "we have detected a slow internet connection! It may interfer with the responsiveness of the BGNIS"
                vm.snackbar = true
                vm.x = "right"
                vm.y = "top"
                vm.mode = "multi-line"
              }
            })
        } else if (
          // Read more about handling dismissals
          result.dismiss === "cancel"
        ) {
          Swal.fire({
            title: "Cancelled",
            text: "Enumerator was not created.",
            icon: "error",
            confirmButtonColor: "#26c6da",
            confirmButtonText: "OK"
          })
          vm.disabled = false
          //vm.closeCreate()
        }
      })
    },
    edit_item(item) {
      var vm = this
      vm.model = Object.assign({}, item)
      vm.dialog_model = true
      vm.status = "edit"
    },
    edit_submit() {
      var vm = this
      Swal.fire({
        title: "Submit",
        text: "Do you want to confirm this edit?",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#26c6da",
        cancelButtonColor: "#F44336",
        confirmButtonText: "Edit",
        cancelButtonText: "Cancel"
      }).then(result => {
        if (result.value) {
          vm.downloaded = true
          vm.disabled = true
          var data = {
            id: vm.model.id,
            name: vm.model.name,
            national_id: vm.model.national_id,
            phone_number: vm.model.phone_number,
            email: vm.model.email,
            status: vm.model.status
          }
          const options = {
            method: "POST",
            headers: getHeader(),
            data: data,
            url: enumeratorsEditUrl
          }
          axios(options)
            .then(function(response) {
              if (response.data.updated) {
                vm.fetchData()
                vm.dialog_model = false
                vm.errors = {}
                vm.downloaded = false
                vm.disabled = false
                //vm.closeCreate()
                Swal.fire({
                  title: "Edited",
                  text: "Edited successfully",
                  icon: "success",
                  confirmButtonColor: "#26c6da",
                  confirmButtonText: "OK"
                })
              }
              if (response.data.failed) {
                vm.downloaded = false
                vm.disabled = false
                //vm.closeCreate()
                Swal.fire({
                  title: "Failed",
                  text: "Edit failed",
                  icon: "success",
                  confirmButtonColor: "#26c6da",
                  confirmButtonText: "OK"
                })
              }
              if (response.data.unauthorized) {
                Swal.fire({
                  title: "403!",
                  text: "ACCESS DENIED!",
                  icon: "warning",
                  confirmButtonColor: "#26c6da",
                  confirmButtonText: "OK"
                })
                vm.sending = false
                vm.disabled = false
                //vm.closeCreate()
                //vm.$router.go(-1)
              }
            })
            .catch(function(error) {
              console.log(error)
              vm.downloaded = false
              if (error.response.data) {
                Vue.set(vm.$data, "errors", error.response.data.errors)
                vm.disabled = false
              }
              if (error.message == "Network Error") {
                vm.snackbar_text =
                  "we have detected a slow internet connection! It may interfer with the responsiveness of the BGNIS"
                vm.snackbar = true
                vm.x = "right"
                vm.y = "top"
                vm.mode = "multi-line"
              }
            })
        } else if (
          // Read more about handling dismissals
          result.dismiss === "cancel"
        ) {
          Swal.fire({
            title: "Cancelled",
            text: "Enumerator was not edited",
            icon: "error",
            confirmButtonColor: "#26c6da",
            confirmButtonText: "OK"
          })
          vm.disabled = false
          //vm.closeCreate()
        }
      })
    }
    /*delete_item(item) {
      var vm = this
      Swal.fire({
        title: "Submit",
        text: "Do you want to delete this Enumerator?",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#26c6da",
        cancelButtonColor: "#F44336",
        confirmButtonText: "Delete",
        cancelButtonText: "Cancel"
      }).then(result => {
        if (result.value) {
          vm.downloaded = true
          vm.disabled = true
          var data = {
            id: item.id
          }
          const options = {
            method: "POST",
            headers: getHeader(),
            data: data,
            url: enumeratorsDeleteUrl
          }
          axios(options)
            .then(function(response) {
              if (response.data.deleted) {
                vm.fetchData()
                vm.dialog_model = false
                vm.errors = {}
                vm.downloaded = false
                vm.disabled = false
                //vm.closeCreate()
                Swal.fire({
                  title: "Deleted",
                  text: "Deleted successfully",
                  icon: "success",
                  confirmButtonColor: "#26c6da",
                  confirmButtonText: "OK"
                })
              }
              if (response.data.failed) {
                vm.downloaded = false
                vm.disabled = false
                //vm.closeCreate()
                Swal.fire({
                  title: "Failed",
                  text: "Delete failed",
                  icon: "success",
                  confirmButtonColor: "#26c6da",
                  confirmButtonText: "OK"
                })
              }
              if (response.data.unauthorized) {
                Swal.fire({
                  title: "403!",
                  text: "ACCESS DENIED!",
                  icon: "warning",
                  confirmButtonColor: "#26c6da",
                  confirmButtonText: "OK"
                })
                vm.sending = false
                vm.disabled = false
                //vm.closeCreate()
                //vm.$router.go(-1)
              }
            })
            .catch(function(error) {
              console.log(error)
              vm.downloaded = false
              if (error.response.data) {
                Vue.set(vm.$data, "errors", error.response.data.errors)
                vm.disabled = false
              }
              if (error.message == "Network Error") {
                vm.snackbar_text =
                  "we have detected a slow internet connection! It may interfer with the responsiveness of the BGNIS"
                vm.snackbar = true
                vm.x = "right"
                vm.y = "top"
                vm.mode = "multi-line"
              }
            })
        } else if (
          // Read more about handling dismissals
          result.dismiss === "cancel"
        ) {
          Swal.fire({
            title: "Cancelled",
            text: "Enumerator was not deleted.",
            icon: "error",
            confirmButtonColor: "#26c6da",
            confirmButtonText: "OK"
          })
          vm.disabled = false
          //vm.closeCreate()
        }
      })
    }*/
  }
}
</script>
