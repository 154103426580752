<template>
  <v-dialog
    persistent
    no-click-animation
    v-model="dialog_osp"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card flat>
      <v-snackbar
        v-model="snackbar"
        :multi-line="mode"
        :right="x"
        :top="y"
        :timeout="timeout"
      >
        {{ snackbar_text }}
        <v-btn color="#26c6da" text @click="snackbar = false">Close</v-btn>
      </v-snackbar>
      <v-chip
        class="mt-4 ml-4 mr-4"
        color="#26c6da"
        outlined
        label
        text-color="#26c6da"
      >
        <v-icon left>
          list
        </v-icon>
        {{ name }}
      </v-chip>
      <v-card
        dark
        color="#26c6da"
        dense
        class="mt-8 mr-8 ml-8"
        style="z-index: 10;"
        elevation="2"
      >
        <v-card-text class="white--text">
          {{ study_name }}
        </v-card-text>
      </v-card>
      <v-card elevation="2" class="mb-4 ml-4 mr-4" style="margin-top: -36px;">
        <v-container fluid>
          <v-row class="mt-6">
            <v-col cols="12" sm="12" md="12" pa-1>
              <v-card flat>
                <v-card-title>
                  <v-btn
                    class="ma-2"
                    outlined
                    color="#26c6da"
                    @click="add_house_hold"
                  >
                    add
                  </v-btn>
                  <v-btn
                    class="ma-2"
                    outlined
                    color="#26c6da"
                    @click="fetchData"
                  >
                    refresh
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                    color="#26c6da"
                  ></v-text-field>
                </v-card-title>
                <v-data-table
                  :headers="headers"
                  :items="models"
                  :search="search"
                  :loading="loaded"
                >
                  <v-progress-linear
                    slot="progress"
                    color="#26c6da"
                    indeterminate
                  ></v-progress-linear>
                  <template v-slot:item.actions="{ item }">
                    <user_questionnaires
                      :hh_id="item.hh_id"
                      :languages="languages"
                      :target_id="item.id"
                      :client_id="client_id"
                      :client_type="client_type"
                      :study_name="study_name"
                      :studies_id="studies_id"
                      @messageFromChild="childMessageReceived"
                      v-if="item.status == 0"
                    ></user_questionnaires>
                    <v-btn outlined disabled v-else>questionnaires</v-btn>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-card>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>
<script>
import Vue from "vue"
import axios from "axios"
import Swal from "sweetalert2"
import { TargetsUrl, addTargetsUrl } from "@/config"
import user_questionnaires from "@/components/user_questionnaires"
export default {
  props: ["dialog_osp", "client_id", "client_type", "studies_id", "study_name"],
  data() {
    return {
      overlay: false,
      show_container_can: false,
      models: [],
      search: "",
      drawer: false,
      snackbar: false,
      submit: false,
      snackbar_text: "",
      y: "",
      x: "",
      mode: "",
      question: "",
      question_id: "",
      answer: "",
      timeout: 6000,
      errors: {},
      loaded: false,
      name: "HOUSEHOLDS LIST",
      card_user_questionnaires: false,
      languages: [],
      headers: [
        {
          text: "Household ID",
          align: "left",
          value: "hh_id"
        },
        {
          text: "Date of Interview",
          align: "left",
          value: "doi"
        },
        { text: "Started", align: "center", value: "created_at" },
        { text: "Closed", align: "left", value: "updated_at" },
        { text: "Actions", align: "center", value: "actions", sortable: false }
      ]
    }
  },
  components: { user_questionnaires },
  watch: {
    dialog_osp: function(newVal, oldVal) {
      // watch it
      var vm = this
      if (newVal == true) {
        vm.fetchData()
      }
    }
  },
  methods: {
    childMessageReceived(value) {
      var vm = this
      if (value == "close") {
        vm.fetchData()
      }
    },
    fetchData() {
      var vm = this
      vm.overlay = true
      vm.loaded = true
      var data = {
        client_id: vm.client_id,
        client_type: vm.client_type,
        studies_id: vm.studies_id
      }
      const options = {
        method: "POST",
        data: data,
        url: TargetsUrl
      }
      axios(options)
        .then(function(response) {
          vm.overlay = false
          vm.loaded = false
          Vue.set(vm.$data, "models", response.data.model)
          Vue.set(vm.$data, "languages", response.data.languages)
        })
        .catch(function(error) {
          console.log(error.message)
          Vue.set(vm.$data, "errors", error.response.data.errors)
          vm.overlay = false
          if (error.message == "Network Error") {
            vm.snackbar_text =
              "we have detected a slow internet connection! It may interfer with the responsiveness of the BGNIS"
            vm.snackbar = true
            vm.x = "right"
            vm.y = "top"
            vm.mode = "multi-line"
          }
        })
    },
    add_house_hold() {
      var vm = this
      Swal.fire({
        title: "Submit",
        text: "Have you received consent from the household?",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#26c6da",
        cancelButtonColor: "#F44336",
        confirmButtonText: "Add",
        cancelButtonText: "Cancel"
      }).then(result => {
        if (result.value) {
          vm.overlay = true
          var data = {
            client_id: vm.client_id,
            client_type: vm.client_type,
            studies_id: vm.studies_id
          }
          const options = {
            method: "POST",
            data: data,
            url: addTargetsUrl
          }
          axios(options)
            .then(function(response) {
              if (response.data.stored) {
                vm.fetchData()
                vm.errors = {}
                vm.overlay = false
                //vm.closeCreate()
                Swal.fire({
                  title: "Added",
                  text: "Added successfully",
                  icon: "success",
                  confirmButtonColor: "#26c6da",
                  confirmButtonText: "OK"
                })
              }
              if (response.data.failed) {
                vm.overlay = false
                Swal.fire({
                  title: "Failed",
                  text: "Add failed",
                  icon: "warning",
                  confirmButtonColor: "#26c6da",
                  confirmButtonText: "OK"
                })
              }
              if (response.data.unauthorized) {
                Swal.fire({
                  title: "403!",
                  text: "ACCESS DENIED!",
                  icon: "warning",
                  confirmButtonColor: "#26c6da",
                  confirmButtonText: "OK"
                })
                vm.overlay = false
              }
            })
            .catch(function(error) {
              console.log(error)
              vm.overlay = false
              if (error.response.data) {
                Vue.set(vm.$data, "errors", error.response.data.errors)
              }
              if (error.message == "Network Error") {
                vm.snackbar_text =
                  "we have detected a slow internet connection! It may interfer with the responsiveness of the BGNIS"
                vm.snackbar = true
                vm.x = "right"
                vm.y = "top"
                vm.mode = "multi-line"
              }
            })
        } else if (
          // Read more about handling dismissals
          result.dismiss === "cancel"
        ) {
          Swal.fire({
            title: "Cancelled",
            text: "Household was not added.",
            icon: "error",
            confirmButtonColor: "#26c6da",
            confirmButtonText: "OK"
          })
          vm.disabled = false
          //vm.closeCreate()
        }
      })
    }
  }
}
</script>
