<template>
  <v-container fill-height justify-center align-center>
    <v-flex xs12 sm9 md8 lg7>
      <v-card class="mt-0 pt-0">
        <v-toolbar class="elevation-0" color="#26c6da" dark>
          <v-avatar size="48">
            <img src="@/assets/logo.svg" alt="avatar" />
          </v-avatar>
          <v-spacer></v-spacer>
          <h3 style="color:white">BGN</h3>
        </v-toolbar>
        <v-card-text>
          <v-container class="mt-5">
            <v-row xs12 md12>
              <v-spacer></v-spacer>
              <how_to></how_to>
            </v-row>
            <v-row xs12 md12>
              <h4>Email</h4>
            </v-row>
            <v-row xs12 md12>
              <v-text-field
                class="input-group--focused"
                color="#26c6da"
                name="email"
                v-model="login.email"
                :error-messages="errors.email"
              ></v-text-field>
            </v-row>
            <v-row xs12 md12>
              <h4>Password</h4>
            </v-row>
            <v-row xs12 md12>
              <v-text-field
                class="input-group--focused"
                color="#26c6da"
                name="password"
                v-model="login.password"
                :append-icon="e3 ? 'visibility_off' : 'visibility'"
                @click:append="e3 = !e3"
                :type="e3 ? 'text' : 'password'"
                :error-messages="errors.password"
                v-on:keyup.enter="Handlelogin"
              ></v-text-field>
            </v-row>
          </v-container>
          <v-btn
            block
            color="#26c6da"
            class="white--text"
            @click.native="Handlelogin"
            v-if="disabled == false"
            >Login</v-btn
          >
          <v-btn block disabled v-if="disabled == true">
            <v-progress-circular
              :width="3"
              :size="15"
              indeterminate
              color="white"
            ></v-progress-circular
            >&nbsp; checking
          </v-btn>
        </v-card-text>
        <!--<v-footer class="white">
          <v-spacer></v-spacer>
          <v-btn color="#26c6da" text @click.native="$router.push('/register')"
            >Register</v-btn
          >
          <v-btn
            color="#26c6da"
            text
            @click.native="$router.push('/password-reset')"
            >Forgot your password?</v-btn
          >
        </v-footer>-->
      </v-card>
    </v-flex>
  </v-container>
</template>
<script>
import Vue from "vue"
import { loginUrl, userUrl, permissionUrl, getHeader } from "@/config"
import axios from "axios"
import Swal from "sweetalert2"
import how_to from "@/components/how_to_guide/one"
export default {
  data() {
    return {
      e3: false,
      errors: {},
      loading: false,
      disabled: false,
      text: "",
      login: {
        email: "",
        password: ""
      },
      PermID: ""
    }
  },
  components: {
    how_to
  },
  methods: {
    Handlelogin() {
      var vm = this
      vm.disabled = true

      const data = {
        email: this.login.email,
        password: this.login.password
      }
      const authUser = {}

      axios
        .post(loginUrl, data)
        .then(function(response) {
          if (response.data.fail_authentication) {
            vm.disabled = false
            Swal.fire({
              title: "422",
              text: "wrong email or password",
              icon: "warning",
              confirmButtonColor: "#26c6da",
              confirmButtonText: "OK"
            })
          } else {
            authUser.access_token = response.data.access_token
            authUser.refresh_token = response.data.refresh_token
            authUser.name = response.data.name
            //authUser.id = response.data.id
            window.$cookies.set("authUser", JSON.stringify(authUser))
            axios
              .get(userUrl, { headers: getHeader() })
              .then(function(response) {
                authUser.email = response.data.email
                window.$cookies.set("authUser", JSON.stringify(authUser))
                axios
                  .get(permissionUrl, { headers: getHeader() })
                  .then(function(response) {
                    window.$cookies.set(
                      "permissions",
                      JSON.stringify(response.data.permission)
                    )

                    vm.$router.push(
                      vm.$route.query.redirect || { name: "dashboard" }
                    )
                  })
              })

            //vm.disabled = false
          }
        })
        .catch(function(error) {
          console.log(error)
          Vue.set(vm.$data, "errors", error.response.data.errors)
          console.log(vm.errors)
          vm.disabled = false
        })
    }
  }
}
</script>
