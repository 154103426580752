<template>
  <v-container fill-height justify-center align-center>
    <v-flex xs12 sm9 md8 lg7>
      <v-card class="mt-0 pt-0">
        <v-toolbar class="elevation-0" color="#26c6da" dark>
          <v-spacer></v-spacer>
          <h3 style="color:white">BGN DATA COLLECTION</h3>
        </v-toolbar>
        <v-card-text>
          <v-container class="mt-5">
            <v-row xs12 md12>
              <h4>Serial</h4>
            </v-row>
            <v-row xs12 md12>
              <v-text-field
                class="input-group--focused"
                color="#26c6da"
                name="password"
                v-model="login.password"
                :append-icon="e3 ? 'visibility_off' : 'visibility'"
                @click:append="e3 = !e3"
                :type="e3 ? 'text' : 'password'"
                :error-messages="errors.serial"
                v-on:keyup.enter="serialSubmit"
              ></v-text-field>
            </v-row>
            <v-row xs12 md12>
              <h4>National Id</h4>
            </v-row>
            <v-row xs12 md12>
              <v-text-field
                class="input-group--focused"
                color="#26c6da"
                name="password"
                v-model="login.national_id"
                :append-icon="e10 ? 'visibility_off' : 'visibility'"
                @click:append="e10 = !e10"
                :type="e10 ? 'text' : 'password'"
                :error-messages="errors.national_id"
                v-on:keyup.enter="serialSubmit"
              ></v-text-field>
            </v-row>
          </v-container>
          <v-btn
            block
            color="#26c6da"
            class="white--text"
            @click.native="serialSubmit"
            v-if="disabled == false"
            >Submit</v-btn
          >
          <v-btn block disabled v-if="disabled == true">
            <v-progress-circular
              :width="3"
              :size="15"
              indeterminate
              color="white"
            ></v-progress-circular
            >&nbsp; checking
          </v-btn>
        </v-card-text>
        <!--<v-footer class="white">
          <v-spacer></v-spacer>
          <v-btn color="#26c6da" text @click.native="$router.push('/register')"
            >Register</v-btn
          >
          <v-btn
            color="#26c6da"
            text
            @click.native="$router.push('/password-reset')"
            >Forgot your password?</v-btn
          >
        </v-footer>-->
      </v-card>
    </v-flex>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <user_targets
      :dialog_osp="user_card_dialog"
      :client_id="client_id"
      :client_type="client_type"
      :study_name="study_name"
      :studies_id="studies_id"
    ></user_targets>
    <data_enumerators
      :dialog_osp="data_card_dialog"
      :client_id="client_id"
      :client_type="client_type"
      :study_name="study_name"
      :studies_id="studies_id"
    ></data_enumerators>
    <study_admins
      :dialog_osp="admin_card_dialog"
      :client_id="client_id"
      :client_type="client_type"
      :studies="studies"
    ></study_admins>
  </v-container>
</template>
<script>
import Vue from "vue"
import { serialCheckUrl, userUrl, permissionUrl, getHeader } from "@/config"
import axios from "axios"
import Swal from "sweetalert2"
import user_targets from "@/components/user_targets"
import data_enumerators from "@/components/data_enumerators"
import study_admins from "@/components/study_admins"
export default {
  data() {
    return {
      e3: false,
      e10: false,
      errors: {},
      loading: false,
      disabled: false,
      text: "",
      login: {
        password: ""
      },
      errors: {},
      PermID: "",
      overlay: false,
      client_id: "",
      client_type: "",
      study_name: "",
      studies_id: "",
      studies: [],
      user_card_dialog: false,
      data_card_dialog: false,
      admin_card_dialog: false
    }
  },
  mounted() {
    /*var vm = this
    if (vm.$route.query.checker == "true") {
      window.$cookies.remove("authUser")
      window.$cookies.remove("permissions")
      vm.$router.push({
        path: "/data-collection",
        query: { checker: "true" }
      })
    } else {
      vm.$router.push({
        path: "/data-collection",
        query: { checker: "true" }
      })
    }*/
    window.$cookies.remove("authUser")
    window.$cookies.remove("permissions")
  },
  beforeRouteLeave: function(to, from, next) {
    // Indicate to the SubComponent that we are leaving the route
    //this.$refs.mySubComponent.prepareToExit();
    // Make sure to always call the next function, otherwise the hook will never be resolved
    // Ref: https://router.vuejs.org/en/advanced/navigation-guards.html
    window.$cookies.remove("authUser")
    window.$cookies.remove("permissions")
    next()
  },
  components: {
    user_targets,
    data_enumerators,
    study_admins
  },
  methods: {
    serialSubmit() {
      var vm = this
      vm.disabled = true
      vm.overlay = true
      const data = {
        serial: vm.login.password,
        national_id: vm.login.national_id
      }

      axios
        .post(serialCheckUrl, data)
        .then(function(response) {
          if (response.data.fail_authentication) {
            vm.overlay = false
            vm.disabled = false
            Swal.fire({
              title: "422",
              text: "Authentication Fail",
              icon: "warning",
              confirmButtonColor: "#26c6da",
              confirmButtonText: "OK"
            })
          } else {
            //console.log(response.data)
            vm.client_id = response.data.client_id
            vm.client_type = response.data.client_type
            vm.study_name = response.data.study_name
            vm.studies_id = response.data.studies_id
            vm.studies = response.data.studies
            if (vm.client_type == "enumerator") {
              vm.user_card_dialog = true
            } else if (vm.client_type == "data_manager") {
              vm.data_card_dialog = true
            } else if (vm.client_type == "study_admin") {
              const authUser = {}
              authUser.access_token = response.data.temp
              //authUser.id = response.data.id
              window.$cookies.set("authUser", JSON.stringify(authUser))
              axios
                .get(userUrl, { headers: getHeader() })
                .then(function(response) {
                  window.$cookies.set("authUser", JSON.stringify(authUser))
                  axios
                    .get(permissionUrl, { headers: getHeader() })
                    .then(function(response) {
                      window.$cookies.set(
                        "permissions",
                        JSON.stringify(response.data.permission)
                      )
                    })
                })
              vm.admin_card_dialog = true
            }

            //vm.serialTarget()
          }
        })
        .catch(function(error) {
          vm.overlay = false
          console.log(error)
          Vue.set(vm.$data, "errors", error.response.data.errors)
          console.log(vm.errors)
          vm.disabled = false
        })
    }
    /*serialTarget() {
      var vm = this
      vm.disabled = true
      vm.overlay = true
      const data = {
        studies_id: vm.login.studies_id
      }

      axios
        .post(serialTargetUrl, data)
        .then(function(response) {

          if (response.data.fail_authentication) {
            vm.overlay = false
            vm.disabled = false
            Swal.fire({
              title: "422",
              text: "wrong serial",
              type: "warning",
              confirmButtonColor: "#26c6da",
              confirmButtonText: "OK"
            })
          } else {
            vm.disabled = false
            console.log(response.data)
            vm.card_open_question = true
             vm.$router.push({ name: "dashboard" })
          }
        })
        .catch(function(error) {
          vm.overlay = false
          console.log(error)
          Vue.set(vm.$data, "errors", error.response.data.errors)
          console.log(vm.errors)
          vm.disabled = false
        })
    }*/
  }
}
</script>
