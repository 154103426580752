<template>
  <v-dialog
    persistent
    no-click-animation
    v-model="dialog_osp"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card flat>
      <v-snackbar
        v-model="snackbar"
        :multi-line="mode"
        :right="x"
        :top="y"
        :timeout="timeout"
      >
        {{ snackbar_text }}
        <v-btn color="#26c6da" text @click="snackbar = false">Close</v-btn>
      </v-snackbar>
      <v-chip
        class="mt-4 ml-4 mr-4"
        color="#26c6da"
        outlined
        label
        text-color="#26c6da"
      >
        <v-icon left>
          list
        </v-icon>
        {{ hh_id }}
      </v-chip>
      <v-card
        dark
        color="#26c6da"
        dense
        class="mt-8 mr-8 ml-8"
        style="z-index: 10;"
        elevation="2"
      >
        <v-card-text class="ma-2 white--text text-body-1 text-sm-h6">
          {{ model.position }}. {{ question }}
          <v-btn
            color="#385F73"
            elevation="1"
            :small="$vuetify.breakpoint.xsOnly"
            fab
            dark
            absolute
            bottom
            right
            @click="fetchData"
          >
            <v-icon color="#26c6da">refresh</v-icon>
          </v-btn>
        </v-card-text>
      </v-card>
      <v-card
        elevation="2"
        class="mb-4 ml-4 mr-4"
        style="margin-top: -36px;"
        color="blue lighten-5"
      >
        <v-container>
          <v-row class="mt-6">
            <v-col cols="12" sm="12" md="12">
              <v-textarea
                label="Response"
                v-model="answer"
                color="#26c6da"
                :error-messages="errors.answer"
                readonly
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
        <v-card elevation="0" class="ma-6" color="blue lighten-5">
          <v-container>
            <v-row class="mt-6">
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  label="Data Manager comment"
                  v-model="comment"
                  color="#26c6da"
                  :error-messages="errors.comment"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="#26c6da" text @click="back_comment">
              BACK
            </v-btn>
            <v-btn
              color="#26c6da"
              class=" white--text"
              depressed
              @click="save_comment"
            >
              SAVE
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-card>
    </v-card>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>
<script>
import Vue from "vue"
import axios from "axios"
import Swal from "sweetalert2"
import { DataOpenQuestionUrl, commentDataTargetsQuestionsUrl } from "@/config"
export default {
  props: ["hh_id", "model", "dialog_osp"],
  data() {
    return {
      overlay: false,
      show_container_can: false,
      models: [],
      searchable: "",
      drawer: false,
      snackbar: false,
      submit: false,
      snackbar_text: "",
      y: "",
      x: "",
      mode: "",
      question: "",
      question_id: "",
      answer: "",
      skip: false,
      disabled: true,
      timeout: 6000,
      errors: {},
      comment: ""
    }
  },
  components: {},
  watch: {
    dialog_osp: function(newVal, oldVal) {
      // watch it
      var vm = this
      if (newVal == true) {
        vm.fetchData()
      }
    }
  },
  methods: {
    childtoParent(value) {
      var vm = this
      vm.$emit("messageFromChild", value)
    },
    fetchData() {
      var vm = this
      vm.overlay = true
      var data = {
        t_question_id: vm.model.id
      }
      const options = {
        method: "POST",
        data: data,
        url: DataOpenQuestionUrl
      }
      axios(options)
        .then(function(response) {
          vm.overlay = false
          Vue.set(vm.$data, "question", response.data.question)
          Vue.set(vm.$data, "answer", response.data.answer)
          Vue.set(vm.$data, "comment", response.data.comment)
          vm.errors = {}
        })
        .catch(function(error) {
          console.log(error.message)
          Vue.set(vm.$data, "errors", error.response.data.errors)
          vm.overlay = false
          if (error.message == "Network Error") {
            vm.snackbar_text =
              "we have detected a slow internet connection! It may interfer with the responsiveness of the BGNIS"
            vm.snackbar = true
            vm.x = "right"
            vm.y = "top"
            vm.mode = "multi-line"
          }
        })
    },
    back_comment() {
      var vm = this
      Swal.fire({
        title: "Back",
        text: "Do you want to move back?",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#26c6da",
        cancelButtonColor: "#F44336",
        confirmButtonText: "Proceed",
        cancelButtonText: "Cancel"
      }).then(result => {
        if (result.value) {
          vm.childtoParent("back_open")
        } else if (
          // Read more about handling dismissals
          result.dismiss === "cancel"
        ) {
          Swal.fire({
            title: "Cancelled",
            text: "Did not move back.",
            icon: "error",
            confirmButtonColor: "#26c6da",
            confirmButtonText: "OK"
          })
          vm.overlay = false
          //vm.closeCreate()
        }
      })
    },
    save_comment() {
      var vm = this
      Swal.fire({
        title: "Save",
        text: "Do you want to save this comment?",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#26c6da",
        cancelButtonColor: "#F44336",
        confirmButtonText: "Proceed",
        cancelButtonText: "Cancel"
      }).then(result => {
        if (result.value) {
          vm.overlay = true
          var data = {
            t_question_id: vm.model.id,
            comment: vm.comment
          }
          const options = {
            method: "POST",
            data: data,
            url: commentDataTargetsQuestionsUrl
          }
          axios(options)
            .then(function(response) {
              if (response.data.stored) {
                vm.overlay = false
                vm.childtoParent("close_open")
                Swal.fire({
                  title: "Saved",
                  text: "Saved successfully",
                  icon: "success",
                  confirmButtonColor: "#26c6da",
                  confirmButtonText: "OK"
                })
              }
              if (response.data.unauthorized) {
                Swal.fire({
                  title: "403!",
                  text: "ACCESS DENIED!",
                  icon: "warning",
                  confirmButtonColor: "#26c6da",
                  confirmButtonText: "OK"
                })
              }
            })
            .catch(function(error) {
              console.log(error)
              vm.overlay = false
              if (error.response.data) {
                Vue.set(vm.$data, "errors", error.response.data.errors)
              }
              if (error.message == "Network Error") {
                vm.snackbar_text =
                  "we have detected a slow internet connection! It may interfer with the responsiveness of the BGNIS"
                vm.snackbar = true
                vm.x = "right"
                vm.y = "top"
                vm.mode = "multi-line"
              }
            })
        } else if (
          // Read more about handling dismissals
          result.dismiss === "cancel"
        ) {
          Swal.fire({
            title: "Cancelled",
            text: "Comment was not saved.",
            icon: "error",
            confirmButtonColor: "#26c6da",
            confirmButtonText: "OK"
          })
          vm.overlay = false
          //vm.closeCreate()
        }
      })
    }
  }
}
</script>
