<template>
  <v-dialog
    persistent
    no-click-animation
    v-model="dialog_osp"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card flat>
      <v-snackbar
        v-model="snackbar"
        :multi-line="mode"
        :right="x"
        :top="y"
        :timeout="timeout"
      >
        {{ snackbar_text }}
        <v-btn color="#26c6da" text @click="snackbar = false">Close</v-btn>
      </v-snackbar>
      <v-chip
        class="mt-4 ml-4 mr-4"
        color="#26c6da"
        outlined
        label
        text-color="#26c6da"
      >
        <v-icon left>
          list
        </v-icon>
        {{ hh_id }}
      </v-chip>
      <v-card
        dark
        color="#26c6da"
        dense
        class="mt-8 mr-8 ml-8"
        style="z-index: 10;"
        elevation="2"
      >
        <v-card-text class="ma-2 white--text text-body-1 text-sm-h6">
          {{ model.position }}. {{ question }}
          <v-btn
            color="#385F73"
            elevation="1"
            :small="$vuetify.breakpoint.xsOnly"
            fab
            dark
            absolute
            bottom
            right
            @click="fetchData"
          >
            <v-icon color="#26c6da">refresh</v-icon>
          </v-btn>
        </v-card-text>
      </v-card>
      <v-card elevation="2" class="mb-4 ml-4 mr-4" style="margin-top: -36px;">
        <v-container fluid>
          <v-row class="mt-11">
            <v-col cols="12" sm="12" md="12">
              <v-card flat>
                <v-data-table
                  :headers="headers"
                  :items="models"
                  :search="search"
                  :loading="loaded"
                >
                  <v-progress-linear
                    slot="progress"
                    color="#26c6da"
                    indeterminate
                  ></v-progress-linear>
                  <template v-slot:item.actions="{ item }">
                    <v-btn
                      class="ma-2"
                      outlined
                      color="#26c6da"
                      @click="open_model(item)"
                      v-if="item.status == 0"
                    >
                      <v-icon>create</v-icon>
                    </v-btn>
                    <v-btn class="ma-2" outlined disabled v-else>
                      <v-icon>create</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
        <v-card elevation="0" class="ma-6" color="blue lighten-5">
          <!--<v-container fluid>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-subheader>ANNEX</v-subheader>
                <v-card-text>{{ annex }}
              </v-col>
            </v-row>
          </v-container>-->
          <v-card-text style="white-space: pre-line"
            ><v-subheader>ANNEX</v-subheader> {{ annex }}</v-card-text
          >
        </v-card>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="#26c6da"
            class=" white--text"
            depressed
            @click="save_close"
          >
            SAVE & CLOSE
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-card>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="dialog_model" persistent max-width="600px">
      <v-card>
        <v-toolbar color="#26c6da" dark class="elevation-0">
          <span class="text-h5">SLOT {{ slot_id }}</span></v-toolbar
        >
        <v-card-text>
          <v-container>
            <v-row v-for="item in model_item" :key="item.column_id">
              <v-col cols="12">
                <v-text-field
                  :label="item.col_label"
                  v-model="item.col_text"
                  color="#26c6da"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            text
            @click="
              dialog_model = false
              model_item = {}
            "
          >
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="store_submit">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="downloaded" persistent width="300">
      <v-card color="#26c6da" dark>
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>
<script>
import Vue from "vue"
import axios from "axios"
import Swal from "sweetalert2"
import {
  TableQuestionUrl,
  saveTableQuestionUrl,
  closeTableQuestionUrl
} from "@/config"
export default {
  props: ["hh_id", "model", "dialog_osp"],
  data() {
    return {
      overlay: false,
      show_container_can: false,
      models: [],
      headers: [],
      loaded: false,
      search: "",
      drawer: false,
      snackbar: false,
      submit: false,
      snackbar_text: "",
      y: "",
      x: "",
      mode: "",
      question: "",
      question_id: "",
      answer: "",
      timeout: 6000,
      errors: {},
      model_item: {},
      dialog_model: false,
      slot_id: "",
      downloaded: false,
      annex: ""
    }
  },
  components: {},
  watch: {
    dialog_osp: function(newVal, oldVal) {
      // watch it
      var vm = this
      if (newVal == true) {
        vm.fetchData()
      }
    }
  },
  methods: {
    childtoParent(value) {
      var vm = this
      vm.$emit("messageFromChild", value)
    },
    open_model(item) {
      var vm = this
      vm.model_item = Object.assign({}, item.model_arr)
      vm.slot_id = item.slot_id
      vm.dialog_model = true
      //console.log(item)
    },
    store_s() {
      console.log(this.model)
    },
    fetchData() {
      var vm = this
      vm.overlay = true
      var data = {
        t_question_id: vm.model.id
      }
      const options = {
        method: "POST",
        data: data,
        url: TableQuestionUrl
      }
      axios(options)
        .then(function(response) {
          vm.overlay = false
          Vue.set(vm.$data, "question", response.data.question)
          Vue.set(vm.$data, "headers", response.data.headers)
          Vue.set(vm.$data, "models", response.data.slots)
          Vue.set(vm.$data, "annex", response.data.annex)
          vm.errors = {}
          //console.log(vm.headers)
        })
        .catch(function(error) {
          console.log(error.message)
          Vue.set(vm.$data, "errors", error.response.data.errors)
          vm.overlay = false
          if (error.message == "Network Error") {
            vm.snackbar_text =
              "we have detected a slow internet connection! It may interfer with the responsiveness of the BGNIS"
            vm.snackbar = true
            vm.x = "right"
            vm.y = "top"
            vm.mode = "multi-line"
          }
        })
    },
    store_submit() {
      var vm = this
      Swal.fire({
        title: "Save",
        text: "Do you want to save this response?",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#26c6da",
        cancelButtonColor: "#F44336",
        confirmButtonText: "Save",
        cancelButtonText: "Cancel"
      }).then(result => {
        if (result.value) {
          vm.downloaded = true
          var data = {
            t_question_id: vm.model.id,
            answer: vm.model_item,
            slot_id: vm.slot_id
          }
          const options = {
            method: "POST",
            data: data,
            url: saveTableQuestionUrl
          }
          axios(options)
            .then(function(response) {
              if (response.data.stored) {
                vm.downloaded = false
                vm.model_item = ""
                vm.dialog_model = false
                vm.fetchData()
                //vm.childtoParent("close_table")
                Swal.fire({
                  title: "Saved",
                  text: "Saved successfully",
                  icon: "success",
                  confirmButtonColor: "#26c6da",
                  confirmButtonText: "OK"
                })
              }
              if (response.data.failed) {
                vm.downloaded = false
                Swal.fire({
                  title: "Failed",
                  text: response.data.message,
                  icon: "warning",
                  confirmButtonColor: "#26c6da",
                  confirmButtonText: "OK"
                })
              }
              if (response.data.unauthorized) {
                Swal.fire({
                  title: "403!",
                  text: "ACCESS DENIED!",
                  icon: "warning",
                  confirmButtonColor: "#26c6da",
                  confirmButtonText: "OK"
                })
              }
            })
            .catch(function(error) {
              console.log(error)
              vm.downloaded = false
              if (error.response.data) {
                Vue.set(vm.$data, "errors", error.response.data.errors)
              }
              if (error.message == "Network Error") {
                vm.snackbar_text =
                  "we have detected a slow internet connection! It may interfer with the responsiveness of the BGNIS"
                vm.snackbar = true
                vm.x = "right"
                vm.y = "top"
                vm.mode = "multi-line"
              }
            })
        } else if (
          // Read more about handling dismissals
          result.dismiss === "cancel"
        ) {
          Swal.fire({
            title: "Cancelled",
            text: "Response was not saved.",
            icon: "error",
            confirmButtonColor: "#26c6da",
            confirmButtonText: "OK"
          })
          vm.overlay = false
          //vm.closeCreate()
        }
      })
    },
    save_close() {
      var vm = this
      Swal.fire({
        title: "Save & Close",
        text: "Do you want to save and close this table?",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#26c6da",
        cancelButtonColor: "#F44336",
        confirmButtonText: "Proceed",
        cancelButtonText: "Cancel"
      }).then(result => {
        if (result.value) {
          vm.overlay = true
          var data = {
            t_question_id: vm.model.id
          }
          const options = {
            method: "POST",
            data: data,
            url: closeTableQuestionUrl
          }
          axios(options)
            .then(function(response) {
              if (response.data.stored) {
                vm.overlay = false
                vm.childtoParent("close_table")
                Swal.fire({
                  title: "Saved",
                  text: "Saved successfully",
                  icon: "success",
                  confirmButtonColor: "#26c6da",
                  confirmButtonText: "OK"
                })
              }
              if (response.data.unauthorized) {
                Swal.fire({
                  title: "403!",
                  text: "ACCESS DENIED!",
                  icon: "warning",
                  confirmButtonColor: "#26c6da",
                  confirmButtonText: "OK"
                })
              }
            })
            .catch(function(error) {
              console.log(error)
              vm.overlay = false
              if (error.response.data) {
                Vue.set(vm.$data, "errors", error.response.data.errors)
              }
              if (error.message == "Network Error") {
                vm.snackbar_text =
                  "we have detected a slow internet connection! It may interfer with the responsiveness of the BGNIS"
                vm.snackbar = true
                vm.x = "right"
                vm.y = "top"
                vm.mode = "multi-line"
              }
            })
        } else if (
          // Read more about handling dismissals
          result.dismiss === "cancel"
        ) {
          Swal.fire({
            title: "Cancelled",
            text: "Table was not saved and closed.",
            icon: "error",
            confirmButtonColor: "#26c6da",
            confirmButtonText: "OK"
          })
          vm.overlay = false
          //vm.closeCreate()
        }
      })
    }
  }
}
</script>
