import Vue from "vue"
import App from "./App.vue"
import vuetify from "./plugins/vuetify"
import router from "./router"
import axios from "axios"
import Auth from "./Auth"
import { securityCheckUrl, getHeader } from "@/config"
import VueCookies from "vue-cookies"

Vue.config.productionTip = false
Vue.use(Auth)
Vue.use(VueCookies)

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount("#app")
window.setIntervalSecurityCheck = setInterval(function() {
  if (
    window.$cookies.isKey("authUser") === true &&
    window.$cookies.isKey("permissions") === true
  ) {
    axios
      .get(securityCheckUrl, { headers: getHeader() })
      .then(function(response) {
        if (response.data.Status == "Unauthenticated") {
          window.$cookies.remove("authUser")
          window.$cookies.remove("permissions")
          window.location.href = "https://www.bgnutrition.co.ke"
          clearInterval(setIntervalSecurityCheck)
          //window.location.href = "http://localhost:8080"
        } else if (response.data.permission) {
          window.$cookies.remove("permissions")
          window.$cookies.set(
            "permissions",
            JSON.stringify(response.data.permission)
          )
        } else {
          window.$cookies.remove("authUser")
          window.$cookies.remove("permissions")
          window.location.href = "https://www.bgnutrition.co.ke"
          clearInterval(setIntervalSecurityCheck)
        }
      })
      .catch(function(error) {
        window.$cookies.remove("authUser")
        window.$cookies.remove("permissions")
        window.location.href = "https://www.bgnutrition.co.ke"
        //clearInterval(setIntervalSecurityCheck)
        //window.location.href = "http://localhost:8080"
      })
  }
}, 40000)
