<template>
  <div>
    <v-snackbar
      v-model="snackbar"
      :multi-line="mode"
      :right="x"
      :top="y"
      :timeout="timeout"
    >
      {{ snackbar_text }}
      <v-btn color="#26c6da" text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-dialog
      persistent
      no-click-animation
      v-model="dialog_osp"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card flat>
        <v-snackbar
          v-model="snackbar"
          :multi-line="mode"
          :right="x"
          :top="y"
          :timeout="timeout"
        >
          {{ snackbar_text }}
          <v-btn color="#26c6da" text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-chip
          class="mt-4 ml-4 mr-4"
          color="#26c6da"
          outlined
          label
          text-color="#26c6da"
        >
          <v-icon left>
            list
          </v-icon>
          {{ hh_id }}
        </v-chip>
        <v-card
          dark
          color="#26c6da"
          dense
          class="mt-8 mr-8 ml-8"
          style="z-index: 10;"
          elevation="2"
        >
          <v-card-text class="ma-2 white--text text-body-1 text-sm-h6">
            Select a language below
          </v-card-text>
        </v-card>
        <v-card
          elevation="2"
          class="mb-4 ml-4 mr-4"
          style="margin-top: -20px;"
          color="blue lighten-5"
        >
          <v-container v-if="language_id == ''">
            <v-row class="mt-6" v-for="item in languages" :key="item.id">
              <v-col cols="12" sm="12" md="12" pa-1>
                <v-card color="#385F73" dark flat>
                  <v-card-title>
                    <span class="text-caption text-md-h6 font-weight-light">{{
                      item.name
                    }}</span>
                    <v-spacer></v-spacer>
                    <v-btn
                      outlined
                      color="white"
                      class="text--white"
                      :block="$vuetify.breakpoint.xsOnly"
                      @click="store_submit(item)"
                      >SELECT</v-btn
                    >
                  </v-card-title>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="#26c6da" text @click="childtoParent">
              CLOSE
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-card>
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-dialog>
    <user_multi_question
      :hh_id="hh_id"
      :model="model"
      :dialog_osp="card_multi_question"
      @messageFromChild="childMessageReceived"
    ></user_multi_question>
    <user_open_question
      :hh_id="hh_id"
      :model="model"
      :dialog_osp="card_open_question"
      @messageFromChild="childMessageReceived"
    ></user_open_question>
    <user_table_question
      :hh_id="hh_id"
      :model="model"
      :dialog_osp="card_table_question"
      @messageFromChild="childMessageReceived"
    ></user_table_question>
  </div>
</template>
<script>
import Vue from "vue"
import axios from "axios"
import Swal from "sweetalert2"
import user_open_question from "@/components/user_open_question"
import user_multi_question from "@/components/user_multi_question"
import user_table_question from "@/components/user_table_question"
import {
  langTargetsQuestionsUrl,
  saveLangTargetsQuestionsUrl,
  fetchTargetsQuestionsUrl
} from "@/config"
export default {
  props: [
    "dialog_osp",
    "languages",
    "hh_id",
    "target_id",
    "studies_id",
    "q_id",
    "q_item"
  ],
  components: {
    user_open_question,
    user_multi_question,
    user_table_question
  },
  data() {
    return {
      overlay: false,
      show_container_can: false,
      models: [],
      model: {},
      searchable: "",
      drawer: false,
      snackbar: false,
      submit: false,
      snackbar_text: "",
      y: "",
      x: "",
      mode: "",
      question: "",
      question_id: "",
      answer: "",
      timeout: 6000,
      questionnaire: "",
      language_id: "",
      errors: {},
      card_open_question: false,
      card_multi_question: false,
      card_table_question: false
    }
  },
  watch: {
    dialog_osp: function(newVal, oldVal) {
      // watch it
      var vm = this
      if (newVal == true) {
        vm.fetchData()
      }
    }
  },
  methods: {
    childtoParent() {
      var vm = this
      vm.$emit("messageFromChild", "close")
    },
    childMessageReceived(value) {
      var vm = this
      if (value == "close_multi") {
        vm.card_multi_question = false
        vm.fetchQuestion()
      }
      if (value == "close_open") {
        vm.card_open_question = false
        vm.fetchQuestion()
      }
      if (value == "close_table") {
        vm.card_table_question = false
        vm.fetchQuestion()
      }
    },
    fetchData() {
      var vm = this
      vm.overlay = true
      var data = {
        t_questionnaire_id: vm.q_id
      }
      console.log(data)
      const options = {
        method: "POST",
        data: data,
        url: langTargetsQuestionsUrl
      }
      axios(options)
        .then(function(response) {
          vm.overlay = false
          Vue.set(vm.$data, "language_id", response.data.language_id)
          vm.fetchQuestion()
          //select from tq use position to get next question and type to launch a question
        })
        .catch(function(error) {
          console.log(error.message)
          //Vue.set(vm.$data, "errors", error.response.data.errors)
          vm.overlay = false
          if (error.message == "Network Error") {
            vm.snackbar_text =
              "we have detected a slow internet connection! It may interfer with the responsiveness of the BGNIS"
            vm.snackbar = true
            vm.x = "right"
            vm.y = "top"
            vm.mode = "multi-line"
          }
        })
    },
    fetchQuestion() {
      var vm = this
      vm.overlay = true
      var data = {
        t_questionnaire_id: vm.q_id,
        questionnaire_id: vm.q_item.id,
        studies_id: vm.studies_id
      }
      const options = {
        method: "POST",
        data: data,
        url: fetchTargetsQuestionsUrl
      }
      axios(options)
        .then(function(response) {
          vm.overlay = false
          //console.log(response.data.model)
          Vue.set(vm.$data, "model", response.data.model)
          if (response.data.type == "open") {
            vm.card_open_question = true
          } else if (response.data.type == "multi") {
            vm.card_multi_question = true
          } else if (response.data.type == "table") {
            vm.card_table_question = true
          } else if (response.data.finished) {
            vm.childtoParent()
            Swal.fire({
              title: "Finished",
              text: "The questionnaire is finished successfully",
              icon: "success",
              confirmButtonColor: "#26c6da",
              confirmButtonText: "OK"
            })
          }
          //select from tq use position to get next question and type to launch a question
        })
        .catch(function(error) {
          console.log(error.message)
          //Vue.set(vm.$data, "errors", error.response.data.errors)
          vm.overlay = false
          if (error.message == "Network Error") {
            vm.snackbar_text =
              "we have detected a slow internet connection! It may interfer with the responsiveness of the BGNIS"
            vm.snackbar = true
            vm.x = "right"
            vm.y = "top"
            vm.mode = "multi-line"
          }
        })
    },
    store_submit(item) {
      var vm = this
      Swal.fire({
        title: "Save",
        text: "Do you want to save " + item.name + " language?",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#26c6da",
        cancelButtonColor: "#F44336",
        confirmButtonText: "Save",
        cancelButtonText: "Cancel"
      }).then(result => {
        if (result.value) {
          vm.overlay = true
          var data = {
            t_questionnaire_id: vm.q_id,
            language_id: item.id,
            questionnaire_id: vm.q_item.id,
            studies_id: vm.studies_id
          }
          const options = {
            method: "POST",
            data: data,
            url: saveLangTargetsQuestionsUrl
          }
          axios(options)
            .then(function(response) {
              vm.overlay = false
              if (response.data.stored) {
                vm.fetchData()
                Swal.fire({
                  title: "Saved",
                  text: "Saved successfully",
                  icon: "success",
                  confirmButtonColor: "#26c6da",
                  confirmButtonText: "OK"
                })
              }
              if (response.data.failed_lang) {
                //vm.fetchData()
                Swal.fire({
                  title: "Failed",
                  text:
                    "This questionnaire is not available in " +
                    item.name +
                    " language",
                  icon: "warning",
                  confirmButtonColor: "#26c6da",
                  confirmButtonText: "OK"
                })
              }
              if (response.data.failed) {
                vm.fetchData()
                Swal.fire({
                  title: "Failed",
                  text: "Language previously saved",
                  icon: "warning",
                  confirmButtonColor: "#26c6da",
                  confirmButtonText: "OK"
                })
              }
              if (response.data.unauthorized) {
                Swal.fire({
                  title: "403!",
                  text: "ACCESS DENIED!",
                  icon: "warning",
                  confirmButtonColor: "#26c6da",
                  confirmButtonText: "OK"
                })
              }
            })
            .catch(function(error) {
              console.log(error)
              vm.overlay = false
              /*if (error.response.data) {
                Vue.set(vm.$data, "errors", error.response.data.errors)
              }*/
              if (error.message == "Network Error") {
                vm.snackbar_text =
                  "we have detected a slow internet connection! It may interfer with the responsiveness of the BGNIS"
                vm.snackbar = true
                vm.x = "right"
                vm.y = "top"
                vm.mode = "multi-line"
              }
            })
        } else if (
          // Read more about handling dismissals
          result.dismiss === "cancel"
        ) {
          Swal.fire({
            title: "Cancelled",
            text: "Language was not saved.",
            icon: "error",
            confirmButtonColor: "#26c6da",
            confirmButtonText: "OK"
          })
          vm.disabled = false
          //vm.closeCreate()
        }
      })
    }
  }
}
</script>
