export default function(Vue) {
    Vue.auth = {
      can(permission) {
        const permissions = JSON.parse(window.$cookies.get("permissions"))
        return permissions.indexOf(permission) != -1
      }
    }
    Object.defineProperties(Vue.prototype, {
      $auth: {
        get() {
          return Vue.auth
        }
      }
    })
  }
  