<template>
  <div>
    <v-snackbar
      v-model="snackbar"
      :multi-line="mode"
      :right="x"
      :top="y"
      :timeout="timeout"
    >
      {{ snackbar_text }}
      <v-btn color="#26c6da" text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-dialog
      persistent
      no-click-animation
      v-model="dialog_osp"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card flat>
        <v-snackbar
          v-model="snackbar"
          :multi-line="mode"
          :right="x"
          :top="y"
          :timeout="timeout"
        >
          {{ snackbar_text }}
          <v-btn color="#26c6da" text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-chip
          class="mt-4 ml-4 mr-4"
          color="#26c6da"
          outlined
          label
          text-color="#26c6da"
        >
          <v-icon left>
            list
          </v-icon>
          {{ hh_id }}
        </v-chip>
        <v-card
          dark
          color="#26c6da"
          dense
          class="mt-8 mr-8 ml-8"
          style="z-index: 10;"
          elevation="2"
        >
          <v-card-text class="ma-2 white--text text-body-1 text-sm-h6">
            Please stand by
          </v-card-text>
        </v-card>
        <v-card
          elevation="2"
          class="mb-4 ml-4 mr-4"
          style="margin-top: -20px;"
          color="blue lighten-5"
        >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="#26c6da" text @click="childtoParent">
              CLOSE
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-card>
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-dialog>
    <data_multi_question
      :hh_id="hh_id"
      :model="model"
      :dialog_osp="card_multi_question"
      @messageFromChild="childMessageReceived"
    ></data_multi_question>
    <data_open_question
      :hh_id="hh_id"
      :model="model"
      :dialog_osp="card_open_question"
      @messageFromChild="childMessageReceived"
    ></data_open_question>
    <data_table_question
      :hh_id="hh_id"
      :model="model"
      :dialog_osp="card_table_question"
      @messageFromChild="childMessageReceived"
    ></data_table_question>
  </div>
</template>
<script>
import Vue from "vue"
import axios from "axios"
import Swal from "sweetalert2"
import data_open_question from "@/components/data_open_question"
import data_multi_question from "@/components/data_multi_question"
import data_table_question from "@/components/data_table_question"
import { langTargetsQuestionsUrl, fetchDataTargetsQuestionsUrl } from "@/config"
export default {
  props: [
    "dialog_osp",
    "languages",
    "hh_id",
    "target_id",
    "studies_id",
    "q_id",
    "q_item"
  ],
  components: {
    data_open_question,
    data_multi_question,
    data_table_question
  },
  data() {
    return {
      overlay: false,
      show_container_can: false,
      models: [],
      model: {},
      searchable: "",
      drawer: false,
      snackbar: false,
      submit: false,
      snackbar_text: "",
      y: "",
      x: "",
      mode: "",
      question: "",
      question_id: "",
      answer: "",
      timeout: 6000,
      questionnaire: "",
      language_id: "",
      errors: {},
      card_open_question: false,
      card_multi_question: false,
      card_table_question: false,
      position: 1
    }
  },
  watch: {
    dialog_osp: function(newVal, oldVal) {
      // watch it
      var vm = this
      if (newVal == true) {
        vm.fetchData()
      }
    }
  },
  methods: {
    childtoParent() {
      var vm = this
      vm.$emit("messageFromChild", "close")
    },
    childMessageReceived(value) {
      var vm = this
      if (value == "close_multi") {
        vm.card_multi_question = false
        vm.fetchQuestion()
      }
      if (value == "back_multi") {
        vm.card_multi_question = false
        vm.fetchQuestion("back")
      }
      if (value == "close_open") {
        vm.card_open_question = false
        vm.fetchQuestion("none")
      }
      if (value == "back_open") {
        vm.card_open_question = false
        vm.fetchQuestion("back")
      }
      if (value == "close_table") {
        vm.card_table_question = false
        vm.fetchQuestion("none")
      }
      if (value == "back_table") {
        vm.card_table_question = false
        vm.fetchQuestion("back")
      }
    },
    fetchData() {
      var vm = this
      vm.overlay = true
      var data = {
        t_questionnaire_id: vm.q_id
      }
      //console.log(data)
      const options = {
        method: "POST",
        data: data,
        url: langTargetsQuestionsUrl
      }
      axios(options)
        .then(function(response) {
          vm.overlay = false
          Vue.set(vm.$data, "language_id", response.data.language_id)
          if (vm.language_id == "") {
            Swal.fire({
              title: "Info",
              text:
                "The enumerator is yet to select a language and begin this questionnaire.",
              icon: "info",
              confirmButtonColor: "#26c6da",
              confirmButtonText: "OK"
            })
            vm.childtoParent()
          }
          vm.fetchQuestion("none")
          //select from tq use position to get next question and type to launch a question
        })
        .catch(function(error) {
          console.log(error.message)
          //Vue.set(vm.$data, "errors", error.response.data.errors)
          vm.overlay = false
          if (error.message == "Network Error") {
            vm.snackbar_text =
              "we have detected a slow internet connection! It may interfer with the responsiveness of the BGNIS"
            vm.snackbar = true
            vm.x = "right"
            vm.y = "top"
            vm.mode = "multi-line"
          }
        })
    },
    fetchQuestion(item) {
      var vm = this
      vm.overlay = true
      var new_position = vm.position
      if (item == "back") {
        if (new_position != 1) {
          new_position = new_position - 2
        }
      }
      var data = {
        t_questionnaire_id: vm.q_id,
        questionnaire_id: vm.q_item.id,
        studies_id: vm.studies_id,
        position: new_position
      }
      const options = {
        method: "POST",
        data: data,
        url: fetchDataTargetsQuestionsUrl
      }
      axios(options)
        .then(function(response) {
          vm.overlay = false
          //console.log(response.data.position)
          Vue.set(vm.$data, "model", response.data.model)
          Vue.set(vm.$data, "position", response.data.position)
          if (response.data.type == "open") {
            vm.card_open_question = true
          } else if (response.data.type == "multi") {
            vm.card_multi_question = true
          } else if (response.data.type == "table") {
            vm.card_table_question = true
          } else if (response.data.finished) {
            vm.childtoParent()
            Swal.fire({
              title: "Info",
              text: "The enumerator has not completed this questionnaire.",
              icon: "info",
              confirmButtonColor: "#26c6da",
              confirmButtonText: "OK"
            })
          } else if (response.data.checked) {
            vm.childtoParent()
            Swal.fire({
              title: "Info",
              text: "The questionnaire is finished successfully",
              icon: "info",
              confirmButtonColor: "#26c6da",
              confirmButtonText: "OK"
            })
          }
          //select from tq use position to get next question and type to launch a question
        })
        .catch(function(error) {
          console.log(error.message)
          //Vue.set(vm.$data, "errors", error.response.data.errors)
          vm.overlay = false
          if (error.message == "Network Error") {
            vm.snackbar_text =
              "we have detected a slow internet connection! It may interfer with the responsiveness of the BGNIS"
            vm.snackbar = true
            vm.x = "right"
            vm.y = "top"
            vm.mode = "multi-line"
          }
        })
    }
  }
}
</script>
