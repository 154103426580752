<template>
  <div>
    <v-row class="ma-1"
      ><v-btn
        outlined
        color="white"
        class="text--white"
        :block="$vuetify.breakpoint.xsOnly"
        @click="dialog_osp = true"
        >open</v-btn
      ></v-row
    >
    <v-dialog
      persistent
      no-click-animation
      v-model="dialog_osp"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card flat>
        <v-snackbar
          v-model="snackbar"
          :multi-line="mode"
          :right="x"
          :top="y"
          :timeout="timeout"
        >
          {{ snackbar_text }}
          <v-btn color="#26c6da" text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-chip
          class="mt-4 ml-4 mr-4"
          color="#26c6da"
          outlined
          label
          text-color="#26c6da"
        >
          <v-icon left>
            list
          </v-icon>
          {{ hh_id }}
        </v-chip>
        <v-card
          dark
          color="#26c6da"
          dense
          class="mt-8 mr-8 ml-8"
          style="z-index: 10;"
          elevation="2"
        >
          <v-card-text class="ma-2 white--text text-body-1 text-sm-h6">
            {{ q_item.name }}
            <v-btn
              color="#385F73"
              elevation="1"
              :small="$vuetify.breakpoint.xsOnly"
              fab
              dark
              absolute
              bottom
              right
              @click="fetchData"
            >
              <v-icon color="#26c6da">refresh</v-icon>
            </v-btn>
          </v-card-text>
        </v-card>
        <v-card
          elevation="2"
          class="mb-4 ml-4 mr-4"
          style="margin-top: -20px;"
          color="blue lighten-5"
        >
          <v-container v-if="multi_check == true">
            <v-row class="mt-6" v-for="item in models" :key="item.id">
              <v-col cols="12" sm="12" md="12" pa-1>
                <v-card color="#385F73" dark flat>
                  <v-card-title>
                    <span class="text-caption text-md-h6 font-weight-light">{{
                      item.choice
                    }}</span>
                    <v-spacer></v-spacer>
                    <v-btn
                      outlined
                      color="white"
                      class="text--white"
                      :block="$vuetify.breakpoint.xsOnly"
                      v-if="item.status == 'OPEN'"
                      @click="
                        card_data_one_question = true
                        q_id = item.q_id
                      "
                      >{{ item.status }}</v-btn
                    >
                    <v-btn
                      outlined
                      color="white"
                      class="text--white"
                      :block="$vuetify.breakpoint.xsOnly"
                      v-if="item.status == 'CREATE'"
                      disabled
                      >{{ item.status }}</v-btn
                    >
                  </v-card-title>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
          <v-container v-if="single_check == true">
            <v-row class="mt-6">
              <v-col cols="12" sm="12" md="12" pa-1>
                <v-card color="#385F73" dark flat>
                  <v-card-title>
                    <span class="text-caption text-md-h6 font-weight-light"
                      >Single Instance</span
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                      outlined
                      color="white"
                      class="text--white"
                      :block="$vuetify.breakpoint.xsOnly"
                      v-if="single_name == 'OPEN'"
                      @click="card_data_one_question = true"
                      >{{ single_name }}</v-btn
                    >
                    <v-btn
                      outlined
                      color="white"
                      class="text--white"
                      :block="$vuetify.breakpoint.xsOnly"
                      v-if="single_name == 'CREATE'"
                      disabled
                      >{{ single_name }}</v-btn
                    >
                  </v-card-title>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="#26c6da" text @click="dialog_osp = false">
              CLOSE
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-card>
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-dialog>
    <data_one_edit_question
      :q_item="q_item"
      :hh_id="hh_id"
      :languages="languages"
      :target_id="target_id"
      :q_id="q_id"
      :dialog_osp="card_data_one_question"
      :studies_id="studies_id"
      @messageFromChild="childMessageReceived"
    ></data_one_edit_question>
  </div>
</template>
<script>
import Vue from "vue"
import axios from "axios"
import Swal from "sweetalert2"
import { oneTargetsQuestionnairesUrl } from "@/config"
import data_one_edit_question from "@/components/data_one_edit_question"
export default {
  props: [
    "q_item",
    "hh_id",
    "languages",
    "target_id",
    "client_id",
    "client_type",
    "studies_id",
    "study_name"
  ],
  data() {
    return {
      overlay: false,
      show_container_can: false,
      models: [],
      searchable: "",
      drawer: false,
      snackbar: false,
      submit: false,
      snackbar_text: "",
      y: "",
      x: "",
      mode: "",
      question: "",
      question_id: "",
      answer: "",
      timeout: 6000,
      multi_check: false,
      single_check: false,
      single_name: "",
      q_id: "",
      questionnaire: "",
      errors: {},
      dialog_osp: false,
      card_data_one_question: false,
      card_user_multi_question: false
    }
  },
  components: {
    data_one_edit_question
  },
  watch: {
    dialog_osp: function(newVal, oldVal) {
      // watch it
      var vm = this
      if (newVal == true) {
        vm.fetchData()
      }
    }
  },
  methods: {
    childMessageReceived(value) {
      var vm = this
      if (value == "close") {
        vm.card_data_one_question = false
      }
    },
    fetchData() {
      var vm = this
      vm.overlay = true
      var data = {
        questionnaire_id: vm.q_item.id,
        studies_id: vm.studies_id,
        target_id: vm.target_id
      }
      const options = {
        method: "POST",
        data: data,
        url: oneTargetsQuestionnairesUrl
      }
      axios(options)
        .then(function(response) {
          vm.overlay = false
          if (response.data.multi_check) {
            Vue.set(vm.$data, "models", response.data.model)
            Vue.set(vm.$data, "multi_check", response.data.multi_check)
            //console.log(vm.models)
          } else if (response.data.single_check) {
            Vue.set(vm.$data, "single_check", response.data.single_check)
            Vue.set(vm.$data, "single_name", response.data.single_name)
            Vue.set(vm.$data, "q_id", response.data.q_id)
            //console.log(vm.q_id)
          }
        })
        .catch(function(error) {
          console.log(error.message)
          Vue.set(vm.$data, "errors", error.response.data.errors)
          vm.overlay = false
          if (error.message == "Network Error") {
            vm.snackbar_text =
              "we have detected a slow internet connection! It may interfer with the responsiveness of the BGNIS"
            vm.snackbar = true
            vm.x = "right"
            vm.y = "top"
            vm.mode = "multi-line"
          }
        })
    }
  }
}
</script>
