<template>
  <v-container fluid v-if="show_container_can === true">
    <nav_tool
      :drawer="drawer"
      @drawerStatus="drawer = $event"
      :key="$route.fullPath"
    >
      <template v-slot:header>BGNIS STUDIES</template>
      <template v-slot:list>
        <v-list-item
          v-for="v_slot in v_slots"
          :key="v_slot.title"
          :style="{ cursor: 'pointer' }"
        >
          <v-list-item-action>
            <v-tooltip right>
              <v-icon slot="activator">{{ v_slot.icon }}</v-icon>
              <span>{{ v_slot.tool_tip }}</span>
            </v-tooltip>
          </v-list-item-action>

          <v-list-item-content @click="$router.push({ name: v_slot.link })">
            <v-list-item-title>{{ v_slot.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </nav_tool>
    <v-col>
      <v-snackbar v-model="snackbar" :multi-line="mode" :right="x" :top="y">
        {{ snackbar_text }}
        <v-btn color="lime" text @click="snackbar = false">Close</v-btn>
      </v-snackbar>
      <v-flex xs12 mb-4>
        <v-toolbar color="white" class="elevation-1">
          <v-app-bar-nav-icon
            pr-0
            class
            @click="drawer = !drawer"
          ></v-app-bar-nav-icon>
          <v-toolbar-title class="headline hidden-sm-and-down"
            >BGN STUDIES</v-toolbar-title
          >
          <v-toolbar-title class="ml-0 mr-2 headline hidden-md-and-up"
            >BGN STUDIES</v-toolbar-title
          >
          <v-spacer></v-spacer>
        </v-toolbar>
      </v-flex>
      <v-row>
        <v-col xs="12" sm="12 " md="5" pa-1>
          <v-card color="#26c6da" dark max-width="400">
            <v-card-title>
              <v-icon large left>
                assignment
              </v-icon>
              <span class="text-h6 font-weight-light">STUDIES LIST</span>
              <v-spacer></v-spacer>
              <studies_list></studies_list>
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-container>
</template>
<script>
import nav_tool from "@/components/nav_tool"
import studies_list from "@/components/studies-list"
export default {
  data() {
    return {
      show_container_can: false,
      models: [],
      searchable: "",
      drawer: false,
      snackbar: false,
      submit: false,
      snackbar_text: "",
      y: "",
      x: "",
      mode: "",
      v_slots: [
        {
          title: "Dashboard",
          icon: "dashboard",
          tool_tip: "Dashboard",
          link: "dashboard"
        }
      ]
    }
  },
  components: {
    studies_list,
    nav_tool
  },
  beforeMount() {
    var vm = this
    vm.show_container_can = true
  },
  methods: {
    changeDrawerStatus(value) {
      this.drawer = value
    }
  }
}
</script>
