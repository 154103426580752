<template>
  <div v-if="show_nav_bar">
    <v-navigation-drawer v-model="drawerChild" absolute temporary>
      <v-list class="pa-0">
        <v-list-item>
          <v-list-item-avatar color="#26c6da" size="48">
            <v-spacer></v-spacer>
            <img src="@/assets/logo.svg" class="mr-1" />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>
              <slot name="header"></slot>
            </v-list-item-title>
          </v-list-item-content>

          <v-list-item-action>
            <v-btn icon @click="drawerChild = !drawerChild">
              <v-icon>chevron_left</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-list class="pt-2" dense>
        <v-divider></v-divider>
        <slot name="list"></slot>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
//import { logoutUrl, getHeader } from "@/config"
export default {
  props: ["drawer"],
  data() {
    return {
      right: null,
      mini: false,
      show_nav_bar: false,
      drawerChild: null
    }
  },
  watch: {
    drawer(value) {
      this.drawerChild = value
    },
    drawerChild(value) {
      this.$emit("drawerStatus", value)
    }
  },
  beforeMount() {
    const authUser = window.$cookies.get("authUser")
    if (authUser.access_token) {
      this.show_nav_bar = true
    }
  },
  methods: {}
}
</script>
