<template>
  <div class="text-xs-center" v-if="show_container_can === true">
    <v-row class="ma-1"
      ><v-btn
        outlined
        fab
        small
        color="#26c6da"
        class="elevation-0"
        @click="dialog_osp = true"
        ><v-icon color="#26c6da">assignment</v-icon></v-btn
      ></v-row
    >
    <v-dialog
      persistent
      no-click-animation
      v-model="dialog_osp"
      width="900"
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-card>
        <v-app-bar dark color="#26c6da" flat>
          <v-toolbar-title>{{ studies_name }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog_osp = false">close</v-btn>
        </v-app-bar>

        <v-container fluid fill-height>
          <v-row>
            <v-col cols="12" sm="12" md="12" pa-1>
              <v-card color="#385F73" dark flat>
                <v-card-title>
                  <span class="text-h6 font-weight-light">Questionnaires</span>
                  <v-spacer></v-spacer>
                  <questionnaires
                    :studies_id="studies_id"
                    :studies_name="studies_name"
                  ></questionnaires>
                </v-card-title>
              </v-card>
            </v-col>
            <v-col cols="12" sm="12" md="12" pa-1>
              <v-card color="#385F73" dark flat>
                <v-card-title>
                  <span class="text-h6 font-weight-light">Enumerators</span>
                  <v-spacer></v-spacer>
                  <enumerators
                    :studies_id="studies_id"
                    :studies_name="studies_name"
                  ></enumerators>
                </v-card-title>
              </v-card>
            </v-col>
            <v-col cols="12" sm="12" md="12" pa-1>
              <v-card color="#385F73" dark flat>
                <v-card-title>
                  <span class="text-h6 font-weight-light">Data Managers</span>
                  <v-spacer></v-spacer>
                  <data_managers
                    :studies_id="studies_id"
                    :studies_name="studies_name"
                  ></data_managers>
                </v-card-title>
              </v-card>
            </v-col>
            <v-col cols="12" sm="12" md="12" pa-1>
              <v-card color="#385F73" dark flat>
                <v-card-title>
                  <span class="text-h6 font-weight-light">Export</span>
                  <v-spacer></v-spacer>
                  <data_export_analysis
                    :studies_id="studies_id"
                    :studies_name="studies_name"
                  ></data_export_analysis>
                </v-card-title>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import questionnaires from "@/components/questionnaires"
import enumerators from "@/components/enumerators.vue"
import data_managers from "@/components/data_managers.vue"
import data_export_analysis from "@/components/data_export_analysis.vue"
export default {
  props: ["studies_id", "studies_name"],
  data() {
    return {
      dialog_osp: false,
      show_container_can: false,
      snackbar: false,
      snackbar_text: "",
      y: "",
      x: "",
      mode: "",
      timeout: 6000,
      models: [],
      statuses: [],
      model: {},
      errors: {},
      downloaded: false,
      disabled: false,
      search: "",
      disabled: false,
      dialog_model: false,
      status: "create"
    }
  },
  components: {
    questionnaires,
    enumerators,
    data_managers,
    data_export_analysis
  },
  beforeMount() {
    var vm = this
    vm.show_container_can = true
  },
  methods: {}
}
</script>
