import Vue from "vue"
import Router from "vue-router"
import Login from "@/views/global/login"
import Dashboard from "@/views/global/dashboard"
import Studies from "@/views/studies/index"
import InputTemplate from "@/views/templates/input-index"
import UserOpenQuestion from "@/components/user_open_question"
import NotFound from "@/views/global/404"

Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: "/",
      name: "login",
      component: Login,
      meta: {
        forVisitors: true
      }
    },
    {
      path: "/data-collection",
      name: "data-collection",
      component: InputTemplate
    },
    {
      path: "/data-collection/open-question",
      name: "data-collection-open-question",
      component: UserOpenQuestion
    },
    {
      path: "/dashboard",
      name: "dashboard",
      component: Dashboard,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/studies",
      name: "studies",
      component: Studies,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "*",
      name: "not-found",
      component: NotFound
    }
  ]
})
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const authUser = window.$cookies.get("authUser")
    if (authUser && authUser.access_token) {
      next()
    } else {
      next({
        name: "login",
        query: { redirect: to.fullPath }
      })
    }
  }
  if (to.matched.some(record => record.meta.forVisitors)) {
    const authUser = window.$cookies.get("authUser")
    if (authUser && authUser.access_token) {
      next({ name: "dashboard" })
    } else {
      next()
    }
  }
  next()
})

export default router
