<template>
  <div class="text-xs-center" v-if="show_container_can === true">
    <v-row class="ma-1"
      ><v-btn outlined block color="white" @click="dialog_osp = true"
        >OPEN</v-btn
      ></v-row
    >

    <v-row justify="center">
      <v-dialog
        persistent
        no-click-animation
        v-model="dialog_osp"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-snackbar
            v-model="snackbar"
            :multi-line="mode"
            :right="x"
            :top="y"
            :timeout="timeout"
          >
            {{ snackbar_text }}
            <v-btn color="lime" text @click="snackbar = false">Close</v-btn>
          </v-snackbar>
          <v-toolbar dark color="#26c6da">
            <v-toolbar-title>STUDY ENUMERATORS</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn dark text @click="fetchData">
                <v-icon>update</v-icon>&nbsp;Refresh
              </v-btn>
              <v-btn dark text @click="dialog_osp = false">Close</v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-card flat>
            <v-card-title>
              <v-btn
                outlined
                color="#26c6da"
                @click="
                  dialog_questionnaire = true
                  qs_id = ''
                  qs_type_id = ''
                  excel_qs()
                "
              >
                QUESTIONNAIRES
              </v-btn>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="models"
              :search="search"
              :loading="loaded"
            >
              <v-progress-linear
                slot="progress"
                color="#26c6da"
                indeterminate
              ></v-progress-linear>
              <template v-slot:item.actions="{ item }">
                <v-btn
                  outlined
                  color="#26c6da"
                  depressed
                  @click="fetchTarget(item)"
                  >households</v-btn
                >
              </template>
            </v-data-table>
          </v-card>
        </v-card>
        <v-dialog v-model="dialog_questionnaire" persistent max-width="600px">
          <v-card flat class="elevation-0">
            <v-toolbar color="#26c6da" dark class="elevation-0"
              ><span class="text-h5">Questionnaires </span></v-toolbar
            >
            <v-card-text>
              <v-stepper v-model="e1" class="elevation-0">
                <v-stepper-header class="elevation-0">
                  <v-stepper-step :complete="e1 > 1" step="1"> </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step :complete="e1 > 2" step="2"> </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items class="elevation-0">
                  <v-stepper-content step="1">
                    <v-card class="mb-12 elevation-0" height="200px">
                      <v-row align="center">
                        <v-col cols="12">
                          <v-select
                            item-text="name"
                            item-value="id"
                            :items="excel_questionnaires"
                            label="Questionnaire"
                            v-model="qs_id"
                            :error-messages="errors.qs_id"
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-card>

                    <v-btn color="#26c6da" depressed @click="excel_qs_type">
                      Continue
                    </v-btn>
                  </v-stepper-content>

                  <v-stepper-content step="2">
                    <v-card class="mb-12 elevation-0" height="200px">
                      <v-row align="center">
                        <v-col cols="12">
                          <v-select
                            item-text="choice"
                            item-value="id"
                            :items="excel_questionnaire_types"
                            label="Questionnaire Choice Type"
                            v-model="qs_type_id"
                            :error-messages="errors.qs_type_id"
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-card>

                    <v-btn color="#26c6da" depressed @click="excel_export">
                      Continue
                    </v-btn>

                    <v-btn
                      text
                      @click="
                        e1 = 1
                        qs_type_id = ''
                      "
                    >
                      Back
                    </v-btn>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="red darken-1"
                text
                @click="
                  qs_id = ''
                  qs_type_id = ''
                  dialog_questionnaire = false
                  errors = {}
                  e1 = 1
                "
              >
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialog_model" persistent>
          <v-card>
            <v-toolbar color="#26c6da" dark class="elevation-0"
              ><span class="text-h5">Households </span></v-toolbar
            >
            <v-card-text>
              <v-container fluid>
                <v-row class="mt-6">
                  <v-col cols="12" sm="12" md="12" pa-1>
                    <v-card flat>
                      <v-card-title>
                        <v-spacer></v-spacer>
                        <v-text-field
                          v-model="search_model"
                          append-icon="mdi-magnify"
                          label="Search"
                          single-line
                          hide-details
                          color="#26c6da"
                        ></v-text-field>
                      </v-card-title>
                      <v-data-table
                        :headers="headers_model"
                        :items="targets"
                        :search="search_model"
                        :loading="loaded"
                      >
                        <v-progress-linear
                          slot="progress"
                          color="#26c6da"
                          indeterminate
                        ></v-progress-linear>
                        <template v-slot:item.actions="{ item }">
                          <v-btn
                            outlined
                            class="ma-2"
                            @click="fetchExport(item)"
                            :disabled="disabled"
                          >
                            EXCEL
                          </v-btn>
                          <v-btn
                            outlined
                            class="ma-2"
                            @click="fetchExportSPSS(item)"
                            :disabled="disabled"
                          >
                            SPSS
                          </v-btn>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="red darken-1"
                text
                @click="
                  targets = []
                  dialog_model = false
                  errors = {}
                "
              >
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="downloaded" persistent width="300">
          <v-card color="#26c6da" dark>
            <v-card-text>
              Please stand by
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-overlay :value="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-dialog>
    </v-row>
  </div>
</template>
<script>
import Vue from "vue"
import axios from "axios"
import Swal from "sweetalert2"
import {
  enumeratorsUrl,
  TargetsUrl,
  DataExportUrl,
  DataExportSPSSUrl,
  ExportQuestionnairesIndexUrl,
  ExportQuestionnaireTypeUrl,
  ExportQuestionnairesUrl,
  getHeader
} from "@/config"
export default {
  props: ["studies_id", "studies_name"],
  data() {
    return {
      e1: 1,
      dialog_osp: false,
      dialog_questionnaire: false,
      show_container_can: false,
      loaded: false,
      snackbar: false,
      snackbar_text: "",
      y: "",
      x: "",
      mode: "",
      timeout: 6000,
      models: [],
      statuses: [],
      model: {},
      errors: {},
      downloaded: false,
      disabled: false,
      search: "",
      disabled: false,
      dialog_model: false,
      status: "create",
      search_model: "",
      overlay: false,
      targets: [],
      qs_id: "",
      qs_type_id: "",
      excel_questionnaires: {},
      excel_questionnaire_types: {},
      headers: [
        { text: "National Id", align: "left", value: "national_id" },
        { text: "Name", align: "left", value: "name" },
        {
          text: "Serial",
          align: "left",
          value: "serial"
        },
        { text: "updated", align: "left", value: "updated_at" },
        { text: "Actions", align: "center", value: "actions", sortable: false }
      ],
      headers_model: [
        {
          text: "Household ID",
          align: "left",
          value: "hh_id"
        },
        {
          text: "Date of Interview",
          align: "left",
          value: "doi"
        },
        { text: "Started", align: "center", value: "created_at" },
        { text: "Closed", align: "left", value: "updated_at" },
        { text: "Actions", align: "center", value: "actions", sortable: false }
      ]
    }
  },
  beforeMount() {
    var vm = this

    //vm.fetchData()
    vm.show_container_can = true
  },
  watch: {
    dialog_osp: function(newVal, oldVal) {
      // watch it
      var vm = this
      if (newVal == true) {
        vm.fetchData()
      }
    }
  },
  methods: {
    fetchData() {
      var vm = this
      vm.loaded = true
      axios
        .get(enumeratorsUrl + "/" + vm.studies_id, { headers: getHeader() })
        .then(function(response) {
          vm.loaded = false
          Vue.set(vm.$data, "models", response.data.model)
          Vue.set(vm.$data, "statuses", response.data.status)
        })
        .catch(function(error) {
          console.log(error.message)
          if (error.message == "Network Error") {
            vm.snackbar_text =
              "we have detected a slow internet connection! It may interfer with the responsiveness of the BGNIS"
            vm.snackbar = true
            vm.x = "right"
            vm.y = "top"
            vm.mode = "multi-line"
          }
        })
    },
    excel_qs() {
      var vm = this
      vm.overlay = true
      vm.loaded = true
      var data = {
        studies_id: vm.studies_id
      }
      const options = {
        method: "POST",
        data: data,
        url: ExportQuestionnairesIndexUrl,
        headers: getHeader()
      }
      axios(options)
        .then(function(response) {
          vm.loaded = false
          Vue.set(vm.$data, "excel_questionnaires", response.data.model)
          vm.overlay = false
        })
        .catch(function(error) {
          console.log(error.message)
          Vue.set(vm.$data, "errors", error.response.data.errors)
          vm.overlay = false
          if (error.message == "Network Error") {
            vm.snackbar_text =
              "we have detected a slow internet connection! It may interfer with the responsiveness of the BGNIS"
            vm.snackbar = true
            vm.x = "right"
            vm.y = "top"
            vm.mode = "multi-line"
          }
        })
    },
    excel_qs_type(item) {
      var vm = this
      vm.overlay = true
      vm.loaded = true
      vm.downloaded = true
      var data = {
        questionnaire_id: vm.qs_id
      }
      const options = {
        method: "POST",
        data: data,
        url: ExportQuestionnaireTypeUrl,
        headers: getHeader()
      }
      axios(options)
        .then(function(response) {
          vm.loaded = false
          Vue.set(vm.$data, "excel_questionnaire_types", response.data.model)
          if (response.data.type_status) {
            vm.downloaded = false
            vm.e1 = 2
          } else {
            vm.excel_export()
          }
          vm.overlay = false
        })
        .catch(function(error) {
          console.log(error.message)
          Vue.set(vm.$data, "errors", error.response.data.errors)
          vm.overlay = false
          if (error.message == "Network Error") {
            vm.snackbar_text =
              "we have detected a slow internet connection! It may interfer with the responsiveness of the BGNIS"
            vm.snackbar = true
            vm.x = "right"
            vm.y = "top"
            vm.mode = "multi-line"
          }
        })
    },
    fetchTarget(item) {
      var vm = this
      vm.overlay = true
      vm.loaded = true
      var data = {
        client_id: item.id,
        client_type: "enumerator",
        studies_id: vm.studies_id
      }
      const options = {
        method: "POST",
        data: data,
        url: TargetsUrl
      }
      axios(options)
        .then(function(response) {
          vm.loaded = false
          Vue.set(vm.$data, "targets", response.data.model)
          vm.dialog_model = true
          vm.overlay = false
        })
        .catch(function(error) {
          console.log(error.message)
          Vue.set(vm.$data, "errors", error.response.data.errors)
          vm.overlay = false
          if (error.message == "Network Error") {
            vm.snackbar_text =
              "we have detected a slow internet connection! It may interfer with the responsiveness of the BGNIS"
            vm.snackbar = true
            vm.x = "right"
            vm.y = "top"
            vm.mode = "multi-line"
          }
        })
    },
    excel_export() {
      var vm = this

      if (vm.qs_id != "") {
        vm.downloaded = true
        vm.disabled = true
        var data = {
          questionnaire_id: vm.qs_id,
          type_choice_id: vm.qs_type_id
        }

        const options = {
          method: "POST",
          headers: getHeader(),
          data: data,
          url: ExportQuestionnairesUrl,
          responseType: "arraybuffer"
        }
        axios(options)
          .then(function(response) {
            vm.downloaded = false
            vm.disabled = false
            //console.log(response)
            if (response.data.byteLength != 0) {
              // It is necessary to create a new blob object with mime-type explicitly set
              // otherwise only Chrome works like it should
              var newBlob = new Blob([response.data], {
                type:
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              })

              // IE doesn't allow using a blob object directly as link href
              // instead it is necessary to use msSaveOrOpenBlob
              if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob)
                return
              }

              // For other browsers:
              // Create a link pointing to the ObjectURL containing the blob.
              const data = window.URL.createObjectURL(newBlob)
              var link = document.createElement("a")
              link.href = data
              link.download = "BG NUTRITION EXPORT.xlsx"
              document.body.appendChild(link)
              link.click()
              setTimeout(function() {
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(data)
              }, 100)
            } else {
              Swal.fire({
                title: "Failed!",
                text: "Not enough data to generate an Export!",
                icon: "info",
                confirmButtonColor: "#26c6da",
                confirmButtonText: "OK"
              })
            }
          })
          .catch(function(error) {
            console.log(error.message)
            vm.disabled = false
            vm.downloaded = false
            if (error.message == "Network Error") {
              vm.snackbar_text =
                "we have detected a slow internet connection! It may interfer with the responsiveness of the BGNIS"
              vm.snackbar = true
              vm.x = "right"
              vm.y = "top"
              vm.mode = "multi-line"
            }
            Swal.fire({
              title: "Failed!",
              text: "Not enough data to generate an Export!",
              icon: "info",
              confirmButtonColor: "#26c6da",
              confirmButtonText: "OK"
            })
          })
      } else {
        Swal.fire({
          title: "Validation!",
          text: "Pick a Questionnaire before exporting!",
          icon: "warning",
          confirmButtonColor: "#26c6da",
          confirmButtonText: "OK"
        })
      }
    },
    fetchExport(item) {
      var vm = this

      if (item.id != "null" && item.id != "") {
        vm.downloaded = true
        vm.disabled = true
        var data = {
          target_id: item.id
        }

        const options = {
          method: "POST",
          headers: getHeader(),
          data: data,
          url: DataExportUrl,
          responseType: "arraybuffer"
        }
        axios(options)
          .then(function(response) {
            vm.downloaded = false
            vm.disabled = false
            //console.log(response)
            if (response.data.byteLength != 0) {
              // It is necessary to create a new blob object with mime-type explicitly set
              // otherwise only Chrome works like it should
              var newBlob = new Blob([response.data], {
                type:
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              })

              // IE doesn't allow using a blob object directly as link href
              // instead it is necessary to use msSaveOrOpenBlob
              if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob)
                return
              }

              // For other browsers:
              // Create a link pointing to the ObjectURL containing the blob.
              const data = window.URL.createObjectURL(newBlob)
              var link = document.createElement("a")
              link.href = data
              link.download = item.hh_id + " BG NUTRITION EXPORT.xlsx"
              document.body.appendChild(link)
              link.click()
              setTimeout(function() {
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(data)
              }, 100)
            } else {
              Swal.fire({
                title: "Failed!",
                text: "Not enough data to generate an Export!",
                icon: "info",
                confirmButtonColor: "#26c6da",
                confirmButtonText: "OK"
              })
            }
          })
          .catch(function(error) {
            console.log(error.message)
            vm.disabled = false
            vm.downloaded = false
            if (error.message == "Network Error") {
              vm.snackbar_text =
                "we have detected a slow internet connection! It may interfer with the responsiveness of the BGNIS"
              vm.snackbar = true
              vm.x = "right"
              vm.y = "top"
              vm.mode = "multi-line"
            }
            Swal.fire({
              title: "Failed!",
              text: "Not enough data to generate an Export!",
              icon: "info",
              confirmButtonColor: "#26c6da",
              confirmButtonText: "OK"
            })
          })
      } else {
        Swal.fire({
          title: "Validation!",
          text: "Pick a household before exporting!",
          icon: "warning",
          confirmButtonColor: "#26c6da",
          confirmButtonText: "OK"
        })
      }
    },
    fetchExportSPSS(item) {
      var vm = this

      if (item.id != "null" && item.id != "") {
        vm.downloaded = true
        vm.disabled = true
        var data = {
          target_id: item.id
        }

        const options = {
          method: "POST",
          headers: getHeader(),
          data: data,
          url: DataExportSPSSUrl,
          responseType: "arraybuffer"
        }
        axios(options)
          .then(function(response) {
            vm.downloaded = false
            vm.disabled = false
            //console.log(response)
            if (response.data.byteLength != 0) {
              // It is necessary to create a new blob object with mime-type explicitly set
              // otherwise only Chrome works like it should
              var newBlob = new Blob([response.data], {
                type:
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              })

              // IE doesn't allow using a blob object directly as link href
              // instead it is necessary to use msSaveOrOpenBlob
              if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob)
                return
              }

              // For other browsers:
              // Create a link pointing to the ObjectURL containing the blob.
              const data = window.URL.createObjectURL(newBlob)
              var link = document.createElement("a")
              link.href = data
              link.download = item.hh_id + " BG NUTRITION EXPORT SPSS.xlsx"
              document.body.appendChild(link)
              link.click()
              setTimeout(function() {
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(data)
              }, 100)
            } else {
              Swal.fire({
                title: "Failed!",
                text: "Not enough data to generate an Export!",
                icon: "info",
                confirmButtonColor: "#26c6da",
                confirmButtonText: "OK"
              })
            }
          })
          .catch(function(error) {
            console.log(error.message)
            vm.disabled = false
            vm.downloaded = false
            if (error.message == "Network Error") {
              vm.snackbar_text =
                "we have detected a slow internet connection! It may interfer with the responsiveness of the BGNIS"
              vm.snackbar = true
              vm.x = "right"
              vm.y = "top"
              vm.mode = "multi-line"
            }
            Swal.fire({
              title: "Failed!",
              text: "Not enough data to generate an Export!",
              icon: "info",
              confirmButtonColor: "#26c6da",
              confirmButtonText: "OK"
            })
          })
      } else {
        Swal.fire({
          title: "Validation!",
          text: "Pick a household before exporting!",
          icon: "warning",
          confirmButtonColor: "#26c6da",
          confirmButtonText: "OK"
        })
      }
    }
  }
}
</script>
