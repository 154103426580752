//export const apiDomain = "http://localhost:8000/"
//export const apiDomain = "https://www.bgnutrition.co.ke/backend/"
export const apiDomain = "https://www.bgnutrition.co.ke/backend/"
export const securityCheckUrl = apiDomain + "api/security-check"
export const serialCheckUrl = apiDomain + "api/serial-check"
export const loginUrl = apiDomain + "api/login"
export const logOutUrl = apiDomain + "api/logout"
export const passResetUrl = apiDomain + "api/password/reset"
export const passTokenUrl = apiDomain + "api/password/token"
export const permissionUrl = apiDomain + "api/user/permissions"
export const userUrl = apiDomain + "api/user"
export const dashboardUrl = apiDomain + "api/dashboard"
export const studiesUrl = apiDomain + "api/studies"
export const studiesAdminUrl = apiDomain + "api/studies-admin"
export const studiesCreateUrl = apiDomain + "api/studies/create"
export const studiesEditUrl = apiDomain + "api/studies/edit"
export const studiesDeleteUrl = apiDomain + "api/studies/delete"
export const questionnairesUrl = apiDomain + "api/questionnaires-index"
export const questionnairesCreateUrl = apiDomain + "api/questionnaires/create"
export const questionnairesEditUrl = apiDomain + "api/questionnaires/edit"
export const questionnairesDeleteUrl = apiDomain + "api/questionnaires/delete"
export const q_TypesUrl = apiDomain + "api/questionnaire-types"
export const q_TypesCreateUrl = apiDomain + "api/questionnaire-types/create"
export const q_TypesEditUrl = apiDomain + "api/questionnaire-types/edit"
export const q_TypesDeleteUrl = apiDomain + "api/questionnaire-types/delete"
export const questionnaireChoicesUrl =
  apiDomain + "api/questionnaire-type-choices-index"
export const questionnaireChoicesCreateUrl =
  apiDomain + "api/questionnaire-type-choices/create"
export const questionnaireChoicesEditUrl =
  apiDomain + "api/questionnaire-type-choices/edit"
export const questionnaireChoicesDeleteUrl =
  apiDomain + "api/questionnaire-type-choices/delete"
export const questionsUrl = apiDomain + "api/questions-index"
export const questionsCreateUrl = apiDomain + "api/questions/create"
export const questionsEditUrl = apiDomain + "api/questions/edit"
export const questionsDeleteUrl = apiDomain + "api/questions/delete"
export const q_t_TypesUrl = apiDomain + "api/question-types"
export const q_t_TypesCreateUrl = apiDomain + "api/question-types/create"
export const q_t_TypesEditUrl = apiDomain + "api/question-types/edit"
export const q_t_TypesDeleteUrl = apiDomain + "api/question-types/delete"
export const languagesUrl = apiDomain + "api/languages"
export const languagesCreateUrl = apiDomain + "api/languages/create"
export const languagesEditUrl = apiDomain + "api/languages/edit"
export const languagesDeleteUrl = apiDomain + "api/languages/delete"
export const q_ChoicesUrl = apiDomain + "api/question-choices-index"
export const q_ChoicesCreateUrl = apiDomain + "api/question-choices/create"
export const q_ChoicesEditUrl = apiDomain + "api/question-choices/edit"
export const q_ChoicesDeleteUrl = apiDomain + "api/question-choices/delete"
export const q_ColumnsUrl = apiDomain + "api/question-table-columns-index"
export const q_ColumnsCreateUrl =
  apiDomain + "api/question-table-columns/create"
export const q_ColumnsEditUrl = apiDomain + "api/question-table-columns/edit"
export const q_ColumnsDeleteUrl =
  apiDomain + "api/question-table-columns/delete"
export const enumeratorsUrl = apiDomain + "api/enumerators-index"
export const enumeratorsCreateUrl = apiDomain + "api/enumerators/create"
export const enumeratorsEditUrl = apiDomain + "api/enumerators/edit"
export const enumeratorsDeleteUrl = apiDomain + "api/enumerators/delete"
export const data_managersUrl = apiDomain + "api/data-managers-index"
export const data_managersCreateUrl = apiDomain + "api/data-managers/create"
export const data_managersEditUrl = apiDomain + "api/data-managers/edit"
export const data_managersDeleteUrl = apiDomain + "api/data-managers/delete"
export const TargetsUrl = apiDomain + "api/targets"
export const addTargetsUrl = apiDomain + "api/targets/create"
export const closeTargetsUrl = apiDomain + "api/targets/close"
export const langTargetsQuestionsUrl = apiDomain + "api/targets/language"
export const saveLangTargetsQuestionsUrl =
  apiDomain + "api/targets/language/create"
export const TargetsQuestionnairesUrl = apiDomain + "api/targets/questionnaires"
export const oneTargetsQuestionnairesUrl =
  apiDomain + "api/targets/one-questionnaires"
export const addTargetsQuestionnairesUrl =
  apiDomain + "api/targets/questionnaires/create"
export const fetchTargetsQuestionsUrl = apiDomain + "api/targets/question/fetch"
export const OpenQuestionUrl = apiDomain + "api/open-question"
export const saveOpenQuestionUrl = apiDomain + "api/open-question/create"
export const editOpenQuestionUrl = apiDomain + "api/open-question/edit"
export const MultiQuestionUrl = apiDomain + "api/multi-question"
export const saveMultiQuestionUrl = apiDomain + "api/multi-question/create"
export const editMultiQuestionUrl = apiDomain + "api/multi-question/edit"
export const TableQuestionUrl = apiDomain + "api/table-question"
export const saveTableQuestionUrl = apiDomain + "api/table-question/create"
export const editTableQuestionUrl = apiDomain + "api/table-question/edit"
export const closeTableQuestionUrl = apiDomain + "api/table-question/close"
export const fetchDataTargetsQuestionsUrl =
  apiDomain + "api/data-managers/targets/question/fetch"
export const commentDataTargetsQuestionsUrl =
  apiDomain + "api/data-managers/targets/question/comment"
export const DataTableQuestionUrl =
  apiDomain + "api/data-managers/table-question"
export const DataOpenQuestionUrl = apiDomain + "api/data-managers/open-question"
export const DataMultiQuestionUrl =
  apiDomain + "api/data-managers/multi-question"
export const DataExportUrl = apiDomain + "api/export-analysis/export"
export const DataExportSPSSUrl = apiDomain + "api/export-analysis/export-spss"
export const ExportQuestionnairesIndexUrl =
  apiDomain + "api/export-analysis/questionnaire"
export const ExportQuestionnaireTypeUrl =
  apiDomain + "api/export-analysis/questionnaire-type"
export const ExportQuestionnairesUrl =
  apiDomain + "api/export-analysis/questionnaire-targets"
export const getHeader = function() {
  const tokenData = window.$cookies.get("authUser")
  const headers = {
    Accept: "application/json",
    Authorization: "Bearer " + tokenData.access_token
  }
  return headers
}

export const getHeaderVisitor = function() {
  const headers = {
    Accept: "application/json"
  }
  return headers
}
