<template v-if="show_container_can === true">
  <v-container fluid grid-list-md v-if="show_container_can === true">
    <v-snackbar v-model="snackbar" :multi-line="mode" :right="x" :top="y">
      {{ snackbar_text }}
      <v-btn color="#26c6da" text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-toolbar color="white" class="elevation-1 mb-4">
      <v-toolbar-title class="headline hidden-sm-and-down"
        >BG NUTRTITION INFORMATION SYSTEM</v-toolbar-title
      >
      <v-toolbar-title class="ml-0 mr-2 headline hidden-md-and-up"
        >BGNIS</v-toolbar-title
      >
      <v-spacer></v-spacer>
      <v-btn
        color="#26c6da"
        dark
        class="mb-2 white--text"
        @click="HandleLogout"
      >
        <v-icon :size="15">logout</v-icon>&nbsp LOGOUT
      </v-btn>
    </v-toolbar>
    <v-progress-linear
      color="#26c6da"
      indeterminate
      v-if="loaded === true"
    ></v-progress-linear>
    <v-data-iterator
      :items="models"
      :items-per-page-options="rowsPerPageItems"
      :options.sync="pagination"
      row
      wrap
      hide-default-footer
    >
      <v-flex slot="item" slot-scope="props" xs12 sm6 md4 lg4>
        <v-card style="height:100%">
          <v-card-title>
            <v-icon color="#26c6da" class="pa-2">{{ props.item.icon }}</v-icon>
            <v-spacer></v-spacer>
            <h4>{{ props.item.name }}</h4>
          </v-card-title>
          <v-card-actions>
            <v-btn
              outlined
              block
              color="#26c6da"
              @click="$router.push({ name: props.item.link })"
              >OPEN</v-btn
            >
          </v-card-actions>
          <v-card-text>
            <!--<v-list>
              <v-list-tile-content>-->{{ props.item.description
            }}<!--</v-list-tile-content>
            </v-list>-->
          </v-card-text>
        </v-card>
      </v-flex>
    </v-data-iterator>
    <v-dialog v-model="downloaded" persistent width="300">
      <v-card color="#26c6da" dark>
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import Vue from "vue"
import axios from "axios"
import Swal from "sweetalert2"
import { dashboardUrl, logOutUrl, getHeader } from "@/config"

export default {
  data() {
    return {
      rowsPerPageItems: [15, 30],
      pagination: {
        rowsPerPage: 15
      },
      snackbar: false,
      snackbar_text: "",
      y: "",
      x: "",
      mode: "",
      show_container_can: false,
      search: "",
      loaded: false,
      sending: false,
      errors: {},
      models: [],
      authUser: "",
      my_url_one: "",
      name: "",
      downloaded: false
    }
  },

  beforeMount() {
    if (this.$auth.can("view global dashboard")) {
      this.fetchData()
      this.show_container_can = true
    } else {
      Swal.fire({
        title: "403!",
        text: "ACCESS DENIED!",
        icon: "warning",
        confirmButtonColor: "#cddc39",
        confirmButtonText: "OK"
      })
      this.$router.go(-1)
    }
  },
  beforeRouteLeave(to, from, next) {
    if (!window.setIntervalConfirm) {
      next()
    } else {
      clearInterval(setIntervalConfirm)
      next()
    }
  },
  mounted() {
    if (this.$auth.can("view global dashboard")) {
      var counter = 0
      var vm = this
      window.setIntervalConfirm = setInterval(function() {
        counter += 1

        if (!vm.models.length) {
          vm.fetchData()
        }

        if (counter === 3) {
          clearInterval(setIntervalConfirm)
        }
      }, 20000)
    }
  },
  methods: {
    fetchData() {
      var vm = this
      vm.loaded = true
      axios
        .get(dashboardUrl, { headers: getHeader() })
        .then(function(response) {
          if (response.data.dashboards) {
            Vue.set(vm.$data, "models", response.data.dashboards)
            vm.loaded = false
          }
          if (response.data.unauthorized) {
            Swal.fire({
              title: "403!",
              text: "ACCESS DENIED!",
              icon: "warning",
              confirmButtonColor: "#cddc39",
              confirmButtonText: "OK"
            })
            //vm.$router.go(-1)
          }
        })
        .catch(function(error) {
          console.log(error.message)
          if (error.response.status == 401) {
            window.$cookies.remove("authUser")
            window.$cookies.remove("permissions")
            window.location.href = "https://www.bgnutrition.co.ke"
            clearInterval(setIntervalSecurityCheck)
          }
          if (error.message == "Network Error") {
            vm.snackbar_text =
              "we have detected a slow internet connection! It may interfer with the responsiveness of the BGNIS"
            vm.snackbar = true
            vm.x = "right"
            vm.y = "top"
            vm.mode = "multi-line"
          }
        })
    },
    HandleLogout() {
      var vm = this
      vm.downloaded = true
      axios.get(logOutUrl, { headers: getHeader() }).then(function(response) {
        if (response.data.loggedOut) {
          vm.downloaded = false
          window.$cookies.remove("authUser")
          window.$cookies.remove("permissions")

          vm.$router.push({ name: "login" })
        }
      })
    }
  }
}
</script>
