<template>
  <div>
    <v-row class="ma-1"
      ><v-btn
        outlined
        color="#26c6da"
        class="text--white"
        @click="dialog_osp = true"
        >EDIT HOUSEHOLDS</v-btn
      ></v-row
    >
    <v-dialog
      persistent
      no-click-animation
      v-model="dialog_osp"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card flat>
        <v-snackbar
          v-model="snackbar"
          :multi-line="mode"
          :right="x"
          :top="y"
          :timeout="timeout"
        >
          {{ snackbar_text }}
          <v-btn color="#26c6da" text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-chip
          class="mt-4 ml-4 mr-4"
          color="#26c6da"
          outlined
          label
          text-color="#26c6da"
        >
          <v-icon left>
            list
          </v-icon>
          {{ name }}
        </v-chip>
        <v-card
          dark
          color="#26c6da"
          dense
          class="mt-8 mr-8 ml-8"
          style="z-index: 10;"
          elevation="2"
        >
          <v-card-text class="white--text">
            {{ study_name }}
          </v-card-text>
        </v-card>
        <v-card elevation="2" class="mb-4 ml-4 mr-4" style="margin-top: -36px;">
          <v-container fluid>
            <v-row class="mt-6">
              <v-col cols="12" sm="12" md="12" pa-1>
                <v-card flat>
                  <v-card-title>
                    <v-spacer></v-spacer>
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Search"
                      single-line
                      hide-details
                      color="#26c6da"
                    ></v-text-field>
                  </v-card-title>
                  <v-data-table
                    :headers="headers"
                    :items="models"
                    :search="search"
                    :loading="loaded"
                  >
                    <v-progress-linear
                      slot="progress"
                      color="#26c6da"
                      indeterminate
                    ></v-progress-linear>
                    <template v-slot:item.actions="{ item }">
                      <v-btn-toggle>
                        <v-row class="ma-1">
                          <v-btn
                            outlined
                            color="#26c6da"
                            class="text--white"
                            @click="fetchExport(item)"
                            :disabled="disabled"
                          >
                            EXCEL
                          </v-btn>
                        </v-row>
                        <data_edit_questionnaires
                          :hh_id="item.hh_id"
                          :languages="languages"
                          :target_id="item.id"
                          :client_id="client_id"
                          :client_type="client_type"
                          :study_name="study_name"
                          :studies_id="studies_id"
                          @messageFromChild="childMessageReceived"
                          v-if="item.status == 0"
                        ></data_edit_questionnaires>
                        <v-btn outlined disabled v-else>questionnaires</v-btn>
                      </v-btn-toggle>
                    </template>
                  </v-data-table>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#26c6da" text @click="childtoParent">
                      CLOSE
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-card>
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-dialog>
    <v-dialog v-model="downloaded" persistent width="300">
      <v-card color="#26c6da" dark>
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Vue from "vue"
import axios from "axios"
import Swal from "sweetalert2"
import { TargetsUrl, addTargetsUrl, DataExportUrl } from "@/config"
import data_edit_questionnaires from "@/components/data_edit_questionnaires"
export default {
  props: [
    "language",
    "model",
    "client_id",
    "client_type",
    "studies_id",
    "study_name"
  ],
  data() {
    return {
      overlay: false,
      show_container_can: false,
      models: [],
      search: "",
      drawer: false,
      snackbar: false,
      submit: false,
      snackbar_text: "",
      y: "",
      x: "",
      mode: "",
      question: "",
      question_id: "",
      answer: "",
      timeout: 6000,
      errors: {},
      loaded: false,
      name: "Enumerators",
      card_data_questionnaires: false,
      dialog_osp: false,
      languages: [],
      downloaded: false,
      headers: [
        {
          text: "Household ID",
          align: "left",
          value: "hh_id"
        },
        {
          text: "Date of Interview",
          align: "left",
          value: "doi"
        },
        { text: "Started", align: "center", value: "created_at" },
        { text: "Closed", align: "left", value: "updated_at" },
        { text: "Actions", align: "center", value: "actions", sortable: false }
      ]
    }
  },
  components: { data_edit_questionnaires },
  watch: {
    dialog_osp: function(newVal, oldVal) {
      // watch it
      var vm = this
      if (newVal == true) {
        vm.overlay = false
        vm.loaded = false
        Vue.set(vm.$data, "models", vm.model)
        Vue.set(vm.$data, "languages", vm.language)
      }
    }
  },
  methods: {
    childtoParent() {
      var vm = this
      vm.$emit("messageFromChild", "close")
      vm.dialog_osp = false
    },
    childMessageReceived(value) {
      var vm = this
      if (value == "close") {
        vm.overlay = false
        vm.loaded = false
        Vue.set(vm.$data, "models", vm.model)
        Vue.set(vm.$data, "languages", vm.language)
      }
    },
    fetchExport(item) {
      var vm = this

      if (item.id != "null" && item.id != "") {
        vm.downloaded = true
        vm.disabled = true
        var data = {
          target_id: item.id
        }

        const options = {
          method: "POST",
          data: data,
          url: DataExportUrl,
          responseType: "arraybuffer"
        }
        axios(options)
          .then(function(response) {
            vm.downloaded = false
            vm.disabled = false
            //console.log(response)
            if (response.data.byteLength != 0) {
              // It is necessary to create a new blob object with mime-type explicitly set
              // otherwise only Chrome works like it should
              var newBlob = new Blob([response.data], {
                type:
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              })

              // IE doesn't allow using a blob object directly as link href
              // instead it is necessary to use msSaveOrOpenBlob
              if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob)
                return
              }

              // For other browsers:
              // Create a link pointing to the ObjectURL containing the blob.
              const data = window.URL.createObjectURL(newBlob)
              var link = document.createElement("a")
              link.href = data
              link.download = item.hh_id + " BG NUTRITION EXPORT.xlsx"
              document.body.appendChild(link)
              link.click()
              setTimeout(function() {
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(data)
              }, 100)
            } else {
              Swal.fire({
                title: "Failed!",
                text: "Not enough data to generate an Export!",
                icon: "info",
                confirmButtonColor: "#26c6da",
                confirmButtonText: "OK"
              })
            }
          })
          .catch(function(error) {
            console.log(error.message)
            vm.disabled = false
            vm.downloaded = false
            if (error.message == "Network Error") {
              vm.snackbar_text =
                "we have detected a slow internet connection! It may interfer with the responsiveness of the BGNIS"
              vm.snackbar = true
              vm.x = "right"
              vm.y = "top"
              vm.mode = "multi-line"
            }
            Swal.fire({
              title: "Failed!",
              text: "Not enough data to generate an Export!",
              icon: "info",
              confirmButtonColor: "#26c6da",
              confirmButtonText: "OK"
            })
          })
      } else {
        Swal.fire({
          title: "Validation!",
          text: "Pick a household before exporting!",
          icon: "warning",
          confirmButtonColor: "#26c6da",
          confirmButtonText: "OK"
        })
      }
    }
  }
}
</script>
