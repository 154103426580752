<template>
  <v-dialog
    persistent
    no-click-animation
    v-model="dialog_osp"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card flat>
      <v-snackbar
        v-model="snackbar"
        :multi-line="mode"
        :right="x"
        :top="y"
        :timeout="timeout"
      >
        {{ snackbar_text }}
        <v-btn color="#26c6da" text @click="snackbar = false">Close</v-btn>
      </v-snackbar>
      <v-chip
        class="mt-4 ml-4 mr-4"
        color="#26c6da"
        outlined
        label
        text-color="#26c6da"
      >
        <v-icon left>
          list
        </v-icon>
        {{ name }}
      </v-chip>
      <v-card
        dark
        color="#26c6da"
        dense
        class="mt-8 mr-8 ml-8"
        style="z-index: 10;"
        elevation="2"
      >
        <v-card-text class="white--text">
          {{ study_name }}
        </v-card-text>
      </v-card>
      <v-card elevation="2" class="mb-4 ml-4 mr-4" style="margin-top: -36px;">
        <v-container fluid>
          <v-row class="mt-6">
            <v-col cols="12" sm="12" md="12" pa-1>
              <v-card flat>
                <v-card-title>
                  <v-btn
                    class="ma-2"
                    outlined
                    color="#26c6da"
                    @click="fetchData"
                  >
                    refresh
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                    color="#26c6da"
                  ></v-text-field>
                </v-card-title>
                <v-data-table
                  :headers="headers"
                  :items="models"
                  :search="search"
                  :loading="loaded"
                >
                  <v-progress-linear
                    slot="progress"
                    color="#26c6da"
                    indeterminate
                  ></v-progress-linear>
                  <template v-slot:item.actions="{ item }">
                    <v-btn-toggle>
                      <data_targets
                        :enum_id="item.id"
                        :model="item.model"
                        :language="languages"
                        :client_id="client_id"
                        :client_type="client_type"
                        :study_name="study_name"
                        :studies_id="studies_id"
                        @messageFromChild="childMessageReceived"
                      ></data_targets>
                      <data_edit_targets
                        :enum_id="item.id"
                        :model="item.model"
                        :language="languages"
                        :client_id="client_id"
                        :client_type="client_type"
                        :study_name="study_name"
                        :studies_id="studies_id"
                        @messageFromChild="childMessageReceived"
                      ></data_edit_targets>
                    </v-btn-toggle>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-card>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>
<script>
import Vue from "vue"
import axios from "axios"
import Swal from "sweetalert2"
import { TargetsUrl } from "@/config"
import data_targets from "@/components/data_targets"
import data_edit_targets from "@/components/data_edit_targets"
export default {
  props: ["dialog_osp", "client_id", "client_type", "studies_id", "study_name"],
  data() {
    return {
      overlay: false,
      show_container_can: false,
      models: [],
      search: "",
      drawer: false,
      snackbar: false,
      submit: false,
      snackbar_text: "",
      y: "",
      x: "",
      mode: "",
      question: "",
      question_id: "",
      answer: "",
      timeout: 6000,
      errors: {},
      loaded: false,
      name: "Enumerators",
      card_data_targets: false,
      languages: [],
      headers: [
        {
          text: "Enumerator",
          align: "left",
          value: "name"
        },
        { text: "Actions", align: "left", value: "actions", sortable: false }
      ]
    }
  },
  components: { data_targets, data_edit_targets },
  watch: {
    dialog_osp: function(newVal, oldVal) {
      // watch it
      var vm = this
      if (newVal == true) {
        vm.fetchData()
      }
    }
  },
  methods: {
    childMessageReceived(value) {
      var vm = this
      if (value == "close") {
        vm.fetchData()
      }
    },
    fetchData() {
      var vm = this
      vm.overlay = true
      vm.loaded = true
      var data = {
        client_id: vm.client_id,
        client_type: vm.client_type,
        studies_id: vm.studies_id
      }
      const options = {
        method: "POST",
        data: data,
        url: TargetsUrl
      }
      axios(options)
        .then(function(response) {
          vm.overlay = false
          vm.loaded = false
          Vue.set(vm.$data, "models", response.data.model)
          Vue.set(vm.$data, "languages", response.data.languages)
        })
        .catch(function(error) {
          console.log(error.message)
          Vue.set(vm.$data, "errors", error.response.data.errors)
          vm.overlay = false
          if (error.message == "Network Error") {
            vm.snackbar_text =
              "we have detected a slow internet connection! It may interfer with the responsiveness of the BGNIS"
            vm.snackbar = true
            vm.x = "right"
            vm.y = "top"
            vm.mode = "multi-line"
          }
        })
    }
  }
}
</script>
