<template>
  <div>
    <v-row class="ma-1"
      ><v-btn
        outlined
        color="#26c6da"
        class="text--white"
        @click="dialog_osp = true"
        >questionnaires</v-btn
      ></v-row
    >
    <v-dialog
      persistent
      no-click-animation
      v-model="dialog_osp"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card flat>
        <v-snackbar
          v-model="snackbar"
          :multi-line="mode"
          :right="x"
          :top="y"
          :timeout="timeout"
        >
          {{ snackbar_text }}
          <v-btn color="#26c6da" text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-chip
          class="mt-4 ml-4 mr-4"
          color="#26c6da"
          outlined
          label
          text-color="#26c6da"
        >
          <v-icon left>
            list
          </v-icon>
          {{ hh_id }}
        </v-chip>
        <v-card
          dark
          color="#26c6da"
          dense
          class="mt-8 mr-8 ml-8"
          style="z-index: 10;"
          elevation="2"
        >
          <v-card-text class="ma-2 white--text text-body-1 text-sm-h6">
            Questionnaires
            <v-btn
              color="#385F73"
              elevation="1"
              :small="$vuetify.breakpoint.xsOnly"
              fab
              dark
              absolute
              bottom
              right
              @click="fetchData"
            >
              <v-icon color="#26c6da">refresh</v-icon>
            </v-btn>
          </v-card-text>
        </v-card>
        <v-card
          elevation="2"
          class="mb-4 ml-4 mr-4"
          style="margin-top: -20px;"
          color="blue lighten-5"
        >
          <v-container>
            <v-row class="mt-6" v-for="item in models" :key="item.id">
              <v-col cols="12" sm="12" md="12" pa-1>
                <v-card color="#385F73" dark flat>
                  <v-card-title>
                    <span class="text-caption text-md-h6 font-weight-light">{{
                      item.name
                    }}</span>
                    <v-spacer></v-spacer>
                    <data_one_questionnaire
                      :q_item="item"
                      :hh_id="hh_id"
                      :languages="languages"
                      :target_id="target_id"
                      :client_id="client_id"
                      :client_type="client_type"
                      :studies_id="studies_id"
                    ></data_one_questionnaire>
                  </v-card-title>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions v-if="overlay == false">
            <v-spacer></v-spacer>
            <v-btn color="#26c6da" text @click="dialog_osp = false">
              CLOSE
            </v-btn>
            <v-btn
              color="#26c6da"
              class=" white--text"
              depressed
              @click="save_close"
            >
              SUBMIT QUESTIONNAIRES
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-card>
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-dialog>
  </div>
</template>
<script>
import Vue from "vue"
import axios from "axios"
import Swal from "sweetalert2"
import data_one_questionnaire from "@/components/data_one_questionnaire"
import { TargetsQuestionnairesUrl, closeTargetsUrl } from "@/config"
export default {
  props: [
    "hh_id",
    "languages",
    "target_id",
    "client_id",
    "client_type",
    "studies_id",
    "study_name"
  ],
  data() {
    return {
      overlay: false,
      show_container_can: false,
      models: [],
      searchable: "",
      drawer: false,
      snackbar: false,
      submit: false,
      snackbar_text: "",
      y: "",
      x: "",
      mode: "",
      question: "",
      question_id: "",
      answer: "",
      timeout: 6000,
      questionnaire: "",
      errors: {},
      dialog_osp: false
    }
  },
  components: {
    data_one_questionnaire
  },
  watch: {
    dialog_osp: function(newVal, oldVal) {
      // watch it
      var vm = this
      if (newVal == true) {
        vm.fetchData()
      }
    }
  },
  methods: {
    childtoParent(value) {
      var vm = this
      vm.$emit("messageFromChild", value)
    },
    fetchData() {
      var vm = this
      vm.overlay = true
      var data = {
        studies_id: vm.studies_id
      }
      const options = {
        method: "POST",
        data: data,
        url: TargetsQuestionnairesUrl
      }
      axios(options)
        .then(function(response) {
          vm.overlay = false
          Vue.set(vm.$data, "models", response.data.model)
        })
        .catch(function(error) {
          console.log(error.message)
          Vue.set(vm.$data, "errors", error.response.data.errors)
          vm.overlay = false
          if (error.message == "Network Error") {
            vm.snackbar_text =
              "we have detected a slow internet connection! It may interfer with the responsiveness of the BGNIS"
            vm.snackbar = true
            vm.x = "right"
            vm.y = "top"
            vm.mode = "multi-line"
          }
        })
    },
    save_close() {
      var vm = this
      //console.log(vm.client_type)
      Swal.fire({
        title: "Save & Close",
        text: "Do you want to save and close " + vm.hh_id + " Questionnaires?",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#26c6da",
        cancelButtonColor: "#F44336",
        confirmButtonText: "Proceed",
        cancelButtonText: "Cancel"
      }).then(result => {
        if (result.value) {
          vm.overlay = true
          var data = {
            target_id: vm.target_id,
            client_id: vm.client_id,
            client_type: vm.client_type
          }
          const options = {
            method: "POST",
            data: data,
            url: closeTargetsUrl
          }
          axios(options)
            .then(function(response) {
              if (response.data.stored) {
                vm.overlay = false
                vm.dialog_osp = false
                vm.childtoParent("close")
                Swal.fire({
                  title: "Saved",
                  text: "Saved successfully",
                  icon: "success",
                  confirmButtonColor: "#26c6da",
                  confirmButtonText: "OK"
                })
              }
              if (response.data.unauthorized) {
                Swal.fire({
                  title: "403!",
                  text: "ACCESS DENIED!",
                  icon: "warning",
                  confirmButtonColor: "#26c6da",
                  confirmButtonText: "OK"
                })
              }
            })
            .catch(function(error) {
              console.log(error)
              vm.overlay = false
              if (error.response.data) {
                Vue.set(vm.$data, "errors", error.response.data.errors)
              }
              if (error.message == "Network Error") {
                vm.snackbar_text =
                  "we have detected a slow internet connection! It may interfer with the responsiveness of the BGNIS"
                vm.snackbar = true
                vm.x = "right"
                vm.y = "top"
                vm.mode = "multi-line"
              }
            })
        } else if (
          // Read more about handling dismissals
          result.dismiss === "cancel"
        ) {
          Swal.fire({
            title: "Cancelled",
            text: vm.hh_id + " Questionnaires were not saved and closed.",
            icon: "error",
            confirmButtonColor: "#26c6da",
            confirmButtonText: "OK"
          })
          vm.overlay = false
          //vm.closeCreate()
        }
      })
    }
  }
}
</script>
